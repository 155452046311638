import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Card, Spin, Divider } from "antd";
import CorridorMap from "./CorridorMap";
import CorridorColorReferences from "./CorridorColorReferences";
import Corridor from "../../../../../../models/Corridor";
import WarehouseService from "../../../../../../services/WarehouseService";
import { useLanguage } from "../../../../../../context/LanguageContext";
import { SweepPositionShow, SweepShow } from "../../../../../../models/Sweep";

const { Panel } = Collapse;

interface CorridorCollapseProps {
  sweepId: string;
  warehouseIdx:string;
  sweep: SweepShow;
  onUpdateContent: ()=>void;
}

const SweepMap: React.FC<CorridorCollapseProps> = (props) => {
  const { language } = useLanguage();
  const [loading, setLoading] = useState<boolean>(true);
  const [stateLoading, setStateLoading] = useState<boolean>(true);
  const [corridors, setCorridors] = useState<Corridor[]>([]);
  const [sweepId] = useState<string>(props.sweepId);
  const startsWithCorridorName = (positions:SweepPositionShow[],corridor:string)=>{return positions.filter((position) => position.position_label.startsWith(corridor));}
  const updateContent = useCallback(() => {
    // setLoading(true);
      if (props.sweep.warehouse){
        props.onUpdateContent();
        WarehouseService.getCorridors(props.warehouseIdx).then((warehouse) => {
          if (warehouse ) {
            setCorridors(warehouse);
          setLoading(false);
          }
        });
      }// eslint-disable-next-line
  }, [sweepId,props.warehouseIdx]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 15*60000);
    return () => clearInterval(interval);
  }, [updateContent]);
  useEffect(() => {
    setStateLoading(false)
  }, [props.sweep]); 
  return (
    <Spin spinning={loading||stateLoading}>
      <CorridorColorReferences />
      <Divider orientation="center">{language==="es"?"Racks":"Corridors"}</Divider>
      <Collapse >
        {corridors.map((corridor) => (
          <Panel header={language==="es"?`Rack ${corridor.name}`:`Corridor ${corridor.name}`} key={corridor.name}>
            <Card>
              <CorridorMap
                sweepId={props.sweepId}
                positions={startsWithCorridorName(props.sweep.positions,corridor.name)}
                corridor={corridor}
              />
            </Card>
          </Panel>
        ))}
      </Collapse>
    </Spin>
  );
};

export default SweepMap;
