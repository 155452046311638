import React from 'react';
import { Button, Col, Row } from 'antd';
import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined, VerticalRightOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import RackTemplate from '../../../../../../models/RackTemplate';

interface NavigationArrowsProps {
  onNavigate: (direction: 'left' | 'right' | 'doubleLeft' | 'doubleRight' | 'topLeft' | 'topRight') => void;
  corridorName: string;
  position: { row: number, col: number };
  racktemplate: RackTemplate;
  hideArrows?: { left?: boolean; right?: boolean }; 
}

const NavigationArrows: React.FC<NavigationArrowsProps> = ({ onNavigate, hideArrows = {} }) => {
  return (
    <Row
    style={{ width: '100%', height: '100%' }}
    gutter={[8, 8]}
    align={"middle"}
    justify={'center'}
  >
    {!hideArrows.left && (
      <Col>
        <Row gutter={[8, 8]} align="middle" justify="center">
          <Col span={8}>
            <Button type="primary" icon={<VerticalRightOutlined />} onClick={() => onNavigate('topLeft')} />
          </Col>
          <Col span={8}>
            <Button icon={<DoubleLeftOutlined />} onClick={() => onNavigate('doubleLeft')} />
          </Col>
          <Col span={8}>
            <Button icon={<LeftOutlined />} onClick={() => onNavigate('left')} />
          </Col>
        </Row>
      </Col>
    )}
    {!hideArrows.right && (
      <Col>
        <Row gutter={[8, 8]} align="middle" justify="center">
          <Col span={8}>
            <Button icon={<RightOutlined />} onClick={() => onNavigate('right')} />
          </Col>
          <Col span={8}>
            <Button icon={<DoubleRightOutlined />} onClick={() => onNavigate('doubleRight')} />
          </Col>
          <Col span={8}>
            <Button type="primary" icon={<VerticalLeftOutlined />} onClick={() => onNavigate('topRight')} />
          </Col>
        </Row>
      </Col>
    )}
  </Row>
  );
};

export default NavigationArrows;
