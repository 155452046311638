
import { Breakpoint, Spin, Table, Tag } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../../../../../../context/UserContext";
import { CheckCircleOutlined, ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { useLanguage } from "../../../../../../context/LanguageContext";
import CorridorStatusBase from "../../../../../../models/CorridorStatus";
import CorridorStatusService from "../../../../../../services/CorridorStatusService";
import CorridorsStatusControls from "./CorridorsStatusControls";

interface CorridorsStatusProps {
  sweepId: string;
}

const CorridorsStatus = (props: CorridorsStatusProps) => {
  const { language } = useLanguage();
  const isMobile = useContext(UserContext).isMobile;
  const [corridorStates, setCorridorStates] = useState<CorridorStatusBase[]>([]);
  const [corridorFilter, setCorridorFilter] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [readingFilter, setReadingFilter] = useState<number[]>([0,100]);
  const [loading, setLoading] = useState(true);
  const updateContent = useCallback(() => {
    if (props.sweepId) {
      CorridorStatusService.getAll(props.sweepId)
        .then((data) => {
          if (data) {
            setCorridorStates(data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching corridor states:", error);
        });
    }
  }, [props.sweepId]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    return () => clearInterval(interval);
  }, [updateContent]);

    const onApplyFilters = useCallback(
         (
           status: string[],
           corridor: string,
           reading: number[]
         ) => {
           setStatusFilter(status)
           setCorridorFilter(corridor);
           setReadingFilter(reading);
         },
         []
       );

       const filter_corridors = () => {
        let filtered = corridorStates;
      
        if (corridorFilter !== "") {
          filtered = filtered.filter((entry) =>
            entry.corridor_name
              .toLocaleUpperCase()
              .includes(corridorFilter.toLocaleUpperCase())
          );
        }
      
        if (readingFilter[0] !== 0 || readingFilter[1] !== 100) {
          filtered = filtered.filter(
            (entry) =>
              entry.reading_percentage >= readingFilter[0] / 100 &&
              entry.reading_percentage <= readingFilter[1] / 100
          );
        }
      
        if (statusFilter.length > 0) {
          filtered = filtered
            .filter((entry) => statusFilter.includes(entry.status))
            .sort((a, b) => statusFilter.indexOf(a.status) - statusFilter.indexOf(b.status)); // Sorting by status logic
        }
      
        return filtered;
      };
      

  const columns = [
    {
      title: "",
      key: "idx",
      width: isMobile ? 30 : 50,
      responsive: ["lg"] as Breakpoint[],
      render: (item: CorridorStatusBase) => item.idx,
    },
    {
      title: language==="es"?"Nombre del Pasillo":"Corridor Name",
      key: "corridor_name",
      width: isMobile ? 60 : 120,
      render: (item: CorridorStatusBase) => item.corridor_name,
    },
    {
      title: (
          <span>
            {language==="es"?"Lectura [%]":" Reading [%]"}
          </span>
      ),
      key: "readings_percentage",
      width: 120,
      render: (item: CorridorStatusBase) => parseFloat((item.reading_percentage * 100).toFixed(2)),
    },
    {
      title: language === "es" ? "Estado" : "Status",
      key: "status",
      width: isMobile ? 60 : 120,
      render: (item: CorridorStatusBase) => {
        switch (item.status) {
          case "in_progress":
            return (
              <Tag icon={<SyncOutlined spin />} color="processing">
                {language === "es" ? "En Curso" : "In Progress"}
              </Tag>
            );
          case "done":
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {language === "es" ? "Terminado" : "Done"}
              </Tag>
            );
          case "unexplored":
            return (
              <Tag icon={<ExclamationCircleOutlined />} color="red">
                {language === "es" ? "Inexplorado" : "Unexplored"}
              </Tag>
          
            );
          case "incomplete":
          return (
            <Tag icon={<ExclamationCircleOutlined />} color="yellow">
              {language === "es" ? "Incompleto" : "Incomplete"}
            </Tag>
        
          );
        }
      },
    }
  ];

  return (
    <>
      <Spin spinning={loading}>
        <CorridorsStatusControls onApply={onApplyFilters}/>
          <Table
            columns={columns}
            dataSource={filter_corridors()}
            scroll={{ x: (isMobile ? 400 : 1150) }}
            rowKey="id"
            size="small"
          />
      </Spin>
    </>
  );
};

export default CorridorsStatus;
