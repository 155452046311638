import { useCallback, useEffect, useState } from "react";
import { Table, Button, Col, Row, Divider, Carousel, Spin, Empty } from "antd";
import { Link, } from "react-router-dom";
import EditableLabel from "../../subcomponents/EditLabel";
import { SweepPositionShow } from "../../../../../../models/Sweep";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ImageDisplayer from "../../../../../utils/ImageDisplayer";
import SweepPictureService from "../../../../../../services/PictureService";



const buttonStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
interface NotReadRevisionProps {
  positions: SweepPositionShow[];
  sweepId: string;
  onUpdateContent: () => void;
  positionLabel: string;
  sweepStatus: string
}


function FullRevision(props: NotReadRevisionProps) {
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState<SweepPositionShow>();
  const [previous, setPrevious] = useState<string | null>(null);
  const [next, setNext] = useState<string | null>(null);


  const updateContent = useCallback(() => {
    setLoading(true);

    const stateFilter = "full"
    var positions_ = trimSpacesInPositionLabels(props.positions)
    positions_ = positions_.filter((entry) => {
      if (entry.state === stateFilter && entry.pallet_label.length>20) {
        return entry.state
          .includes(stateFilter);
      } else {
        return null
      }
    })
    var positionLabel_ = props.positionLabel
    if (positionLabel_ === "0" && positions_.length > 0) {
      positionLabel_ = positions_[0].position_label
    }
    // Find the index of the current positionLabel
    var currentPositionIndex = positions_.findIndex(position => position.position_label === positionLabel_);
    // Calculate the index of the previous positionLabel
    const previousPositionIndex = currentPositionIndex > 0 ? currentPositionIndex - 1 : null;
    setPrevious(previousPositionIndex !== null ? positions_[previousPositionIndex].position_label : null)


    // Calculate the index of the next positionLabel
    const nextPositionIndex = currentPositionIndex < positions_.length - 1 ? currentPositionIndex + 1 : null;
    setNext(nextPositionIndex !== null ? positions_[nextPositionIndex].position_label : null)
    setPosition(positions_[currentPositionIndex])
    setLoading(false);


    // eslint-disable-next-line
  }, [props.positions, props.positionLabel]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 6000000);
    return () => clearInterval(interval);
  }, [updateContent]);
  const trimSpacesInPositionLabels = (sweepPositions: SweepPositionShow[]): SweepPositionShow[] => {
    return sweepPositions.map(position => {
      position.position_label = position.position_label.trim();
      return position;
    });
  };
  const columns = [
    {
      title: "Position Label",
      key: "position_label",
      render: (item: SweepPositionShow) => {
        return (
          <EditableLabel
            prev_position_label={item.position_label}
            label={item.position_label}
            labelType="position"
            isEmpty={item.state === "empty" ? true : false}
            onUpdateContent={props.onUpdateContent}
            repeated={false}
            previous={previous}
            editable={false}
            next={next}
            sweepId={props.sweepId}
            tab="4"
          />
        );
      },
    },
    {
      title: "Pallet Label",
      key: "pallet_label",
      render: (item: SweepPositionShow) => {
        if (item.state === "empty") {
          return (
            <EditableLabel
              editable={props.sweepStatus === "processing" ? true : false}
              onUpdateContent={props.onUpdateContent}
              label={"Empty"}
              prev_position_label={item.position_label}
              labelType={"pallet"}
              isEmpty={true}
              repeated={false}
              previous={previous}
              next={next}
              sweepId={props.sweepId}
              tab="4"
            />
          );
        }
        return (
          <EditableLabel
            editable={props.sweepStatus === "processing" ? true : false}
            onUpdateContent={props.onUpdateContent}
            label={
              item.pallet_label !== undefined && item.pallet_label !== null
                ? item.pallet_label
                : ""
            }
            prev_position_label={item.position_label}
            labelType={"pallet"}
            isEmpty={false}
            repeated={false}
            previous={previous}
            next={next}
            sweepId={props.sweepId}
            tab="4"
          />
        );
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      {position ?
        <Spin spinning={loading}>
          <Row gutter={[8, 8]} justify="center" align="middle">
            <Col xl={14} lg={24} style={{ height: "550px" }}>
            <Carousel
              arrows={true}
              prevArrow={<LeftOutlined />}
              nextArrow={<RightOutlined />}
              infinite={false}
              style={{
                margin: 0,
                color: "#fff",
                textAlign: "center",
              }}
            >
              <div>
              <ImageDisplayer
                  fetchFunction={() => {
                    const paddedPositionLabel = position.position_label.padStart(5, ' ');
                    return SweepPictureService.getSweepPositionPicture(parseInt(props.sweepId), paddedPositionLabel);
                  }}
                  height={"50vh"}
                  style={{maxHeight: "500px"}}
                />
              </div>
              {/* <div>
                <Image
                  src={
                    API_BASE_URL.slice(0, -1) +
                    props.position.position_path + "/crop.jpg"
                  }
                  alt={"Unable to load image."}
                  width={"95%"}
                  style={{
                    maxHeight: "500px",
                  }}
                ></Image>
              </div> */}
              <div>
                <ImageDisplayer
                  fetchFunction={() => {
                    const paddedPositionLabel = position.position_label.padStart(5, ' ');
                    return SweepPictureService.getSweepPalletPicture(parseInt(props.sweepId), paddedPositionLabel);
                  }}
                  height={"50vh"}
                  style={{maxHeight: "500px"}}
                />
              </div>
              {/* <div>
                <Image
                  src={
                    API_BASE_URL.slice(0, -1) +
                    props.position.pallet_path + "/crop.jpg"
                  }
                  alt={"Unable to load image."}
                  width={"95%"}
                  style={{
                    maxHeight: "500px",
                  }}
                ></Image>
              </div> */}
            </Carousel>
            </Col>
            <Col xl={10} lg={24}>
              <Row gutter={20}>
                <Col span={24}>
                  <Divider> </Divider>
                  <Table
                    columns={columns}
                    dataSource={[position]}
                    rowKey="id"
                    pagination={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <div style={buttonStyle}>
            <Link
              to={
                "/sweeps/" + props.sweepId + "/revision/?tab=4&positionLabel=" + previous
              }
            >
              <Button type="primary" disabled={previous === null}>
                Previous
              </Button>
            </Link>
            <Link
              to={
                "/sweeps/" + props.sweepId + "/revision/?tab=4&positionLabel=" + next
              }
            >
              <Button type="primary" disabled={next === null} >
                Next
              </Button>
            </Link>
          </div>
        </Spin>
        :

        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
    </Spin>
  );
}

export default FullRevision;