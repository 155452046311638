import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card, Space, Spin, Tabs, TabsProps, Tag } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";

import SweepMap from "./tab3/SweepMap";
import SweepService from "../../../../../services/SweepService";
import { SweepShow } from "../../../../../models/Sweep";
import Positions from "./tab1/Positions";
import BigPicture from "./tab4/BigPicture";
import SettingsService from "../../../../../services/SettingsService";
import UserContext from "../../../../../context/UserContext";
import { ExportOutlined } from "@ant-design/icons";
import { useLanguage } from "../../../../../context/LanguageContext";
import CorridorsStatus from "./tab5/CorridorsStatus";

const SweepDetails = () => {
  const isMobile = useContext(UserContext).isMobile;
  const { language } = useLanguage();
  const { sweepId } = useParams();
  const [showPictures, setShowPictures] = useState<boolean>(false)

  const [sweep, setSweep] = useState(new SweepShow());
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  let tabId = searchParams.get("tab") || "1";
  const navigate = useNavigate()
  const updateContent = useCallback(() => {
    if (sweepId) {
      // setLoading(true);
      SweepService.getOne(sweepId, navigate).then((sweep) => {
        if (sweep == null) {
          return;
        }
        setSweep(sweep);
        setLoading(false);
      });
      SettingsService.getSettings().then((settings) => {
        if (settings === null) {
          return
        }
        setShowPictures(settings.show_sweep_positions_pictures)
      })
    }
  }, [sweepId, navigate]);
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 60000);
    return () => clearInterval(interval);
  }, [updateContent]);


  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label:language==="es"?"Resumen":"Summary",
      children: sweep.positions && (
        <Positions
          sweepStatus={sweep.status}
          sweepId={sweep.idx}
          positions={sweep.positions}
          showPictures={showPictures}
        />
      ),
    },
    {
      key: "2",
      label: language==="es"?"Mapa":"Map",
      children:
        sweepId === undefined ? (
          <></>
        ) : (
          sweep.idx&& sweep.warehouse && <SweepMap onUpdateContent={updateContent} sweepId={sweep.idx.toString()} warehouseIdx={sweep.warehouse.idx.toString()} sweep={sweep} />
        ),
    },
    {
      key: "3",
      label: language==="es"?"Gigantografía":"Big Picture",
      children:
        sweepId === undefined ? (
          <></>
        ) : (
          sweep.idx && <BigPicture sweepId={sweepId} sweep={sweep} />
        ),
    },
    {
      key: "4",
      label: language === "es" ? "Estados de los corredores" : "Corridors status",
      children: 
        sweepId === undefined ? (
          <></>
        ) : (
          sweep.idx && <CorridorsStatus sweepId={sweepId}/>
        ),
    },
  ];
  return (
    <>
      <Spin spinning={loading}>
        <Card title={<Space>{language==="es"?sweep.counting_number ? "Barrido: " + sweep.counting_number + " - " + sweep.warehouse?.name : "":sweep.counting_number ? "Sweep: " + sweep.counting_number + " - " + sweep.warehouse?.name : ""}<Tag color={sweep.status === "processing" ? "blue" : sweep.status === "processed" ? "green" : "red"}>
          <h6 style={{ margin: 0, textAlign: "center", width: "100%" }}>
            {sweep.timestamp_start.split(" ")[0]}
          </h6>
        </Tag></Space>}
          style={{
            margin: isMobile ? '10px' : '20px',
            padding: isMobile ? '10px' : '20px',
          }}>

          <Tabs
            destroyInactiveTabPane={true}
            items={tabs}
            activeKey={tabId}
            onTabClick={(activeKey) => {
              setSearchParams({ tab: activeKey });
            }}
          />
        </Card>
      </Spin>
    </>
  );
};

export default SweepDetails;
