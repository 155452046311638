import React, { useCallback,  useState } from "react";
import { Button, Popover, Modal, Col, Row, Card } from "antd";
import Position from "../../../../../../models/Position";
// import WarehouseService from "../../../../../../services/WarehouseService";
// import CorridorService from "../../../../../../services/CorridorService";
import { CorridorShow } from "../../../../../../models/Corridor";
import ImageDisplayer from "../../../../../utils/ImageDisplayer";
import { WarehousePictureService } from "../../../../../../services/PictureService";
import { WarehouseThumbnailService } from "../../../../../../services/ThumbnailService";
import { useLanguage } from "../../../../../../context/LanguageContext";

// Modal component to display images
const ImageModal: React.FC<ImageModalProps> = ({ visible, warehouseId, modalPosition, onClose }) => {
  const { language } = useLanguage();

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose={true}
      title={language === "es" ? `Posición: ${modalPosition}`:`Position: ${modalPosition}`}
    >
      <Row justify="space-around" align="middle">
        <Col span={"100px"}>
          <Card style={{ width: "230px", textAlign: "center", height: "100" }}>
            <ImageDisplayer
              fetchFunction={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return WarehousePictureService.getWarehousePositionPicture(warehouseId, paddedPositionLabel);
              }}
              lowfetchFuntion={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return WarehouseThumbnailService.getWarehousePositionThumbnail(warehouseId, paddedPositionLabel);
              }}
              height={"100px"}
            />
          </Card>
        </Col>
        <Col span={"100px"}>
          <Card style={{ width: "230px", textAlign: "center", height: "100" }}>
            <ImageDisplayer
              fetchFunction={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return WarehousePictureService.getWarehousePalletPicture(warehouseId, paddedPositionLabel);
              }}
              lowfetchFuntion={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return WarehouseThumbnailService.getWarehousePalletThumbnail(warehouseId, paddedPositionLabel);
              }}
              height={"100px"}
            />
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};
interface ImageModalProps {
  visible: boolean;
  warehouseId: number;
  modalPosition: string;
  onClose: () => void;
}
interface GridProps {
  warehouseId: string;
  corridorInfo: CorridorShow;
  isMobile: boolean;
  corridorPositions:Position[]
}
type ButtonSize = 'small' | 'middle' | 'large';
const CorridorMap: React.FC<GridProps> = ({ corridorInfo, warehouseId, isMobile,corridorPositions }) => {
  const { language } = useLanguage();
  const [positions] = useState<Position[]>(corridorPositions);
  const [corridor] = useState<CorridorShow>(corridorInfo);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalPosition, setModalPosition] = useState<string>("");

  const buttonSize: ButtonSize = isMobile ? 'large' : 'small';
  // const updateContent = useCallback(() => {
  //   setLoading(true)
  //   CorridorService.getOne(corridorId.toString()).then((corridor) => {
  //     if (corridor === null) {
  //       return;
  //     }
  //     WarehouseService.getPositionsByCorridor(warehouseId, corridor.name).then(
  //       (positions) => {
  //         if (positions === null || corridor === undefined) {
  //           return;
  //         }
  //         setCorridor(corridor);
  //         setPositions(positions);
  //         setLoading(false)
  //       }
  //     );
  //   });
  // }, [warehouseId, corridorId]);

  // useEffect(() => {
  //   updateContent();
  //   const interval = setInterval(() => {
  //     updateContent();
  //   }, 600000);
  //   return () => clearInterval(interval);
  // }, [updateContent]);

  const handlePopoverClick = useCallback((position: Position | undefined) => {
    if (position) {
      setModalPosition(position.position_label)
    }
    if (position === undefined) {
      console.log("HERE 1")
      return
    }
    setModalVisible(true);
  }, []);

  return (
    <Row
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        overflow: "scroll"
      }}
      >
      {corridor && corridor.rack_template && (
        Array.from({ length: corridor.rack_template.level_range[1] - corridor.rack_template.level_range[0] + 1 }, (_, i) => (
          <div
          key={`row-${i}`}
          style={{ display: "flex", justifyContent: "center" }}
          >
            {corridor?.rack_template && Array.from({ length: corridor.rack_template.column_range[1] - corridor.rack_template.column_range[0] + 1 }, (_, j) => {
              let colText = corridor.rack_template?.column_range ? String(j + corridor.rack_template.column_range[0]).padStart(2, "0") : '';
              if (corridor.rack_template?.orientation === "right") {
                colText = corridor.rack_template?.column_range ? String(corridor.rack_template.column_range[1] - j).padStart(2, "0") : '';
              }
              const levelText = corridor.rack_template?.level_range ? String(i + corridor.rack_template.level_range[0]) : '';
              const positionText = corridor.name + colText + levelText;
              const matchingPosition = positions.find((position) => position.position_label === positionText);
              var palletLabel = matchingPosition?.pallet_label ?? (language === "es" ? "Vacía":"Empty Position");
              palletLabel = matchingPosition?.state === "unknown" ?language === "es"?"Sin explorar": "Unexplored" : palletLabel;
              const popoverContent = matchingPosition ? language === "es"? `Etiqueta de pallet: ${palletLabel}`: `Pallet Label: ${palletLabel}` : "";
              const buttonStyle = {
                margin: "2px",
                backgroundColor: "#FF5252",
                fontSize: buttonSize === 'large' ? '16px' : '12px',
              };
              if (matchingPosition) {
                switch (matchingPosition.state) {
                  case "full":
                    buttonStyle.backgroundColor = "#4CAF50"; // Green for "full"
                    break;
                  case "empty":
                    buttonStyle.backgroundColor = "#a0d911"; // Lime for "empty"
                    break;
                    case "unknown":
                      buttonStyle.backgroundColor = "#FF5252"; // Red for "unknown"
                      break;
                      default:
                        buttonStyle.backgroundColor = "#bfbfbf";
                        break;
                      }
                    }
                    else{
                buttonStyle.backgroundColor = "#bfbfbf";
              }
              return (
                <Popover
                  title={!isMobile ? language === "es" ? `Posición: ${positionText}`:`Position: ${positionText}` : ""}
                  key={`tooltip-${i}-${j}`}
                  content={<p>{popoverContent}</p>}
                >
                  <Button
                    ghost
                    key={`button-${i}-${j}`}
                    type={"primary"}
                    size={buttonSize}
                    style={buttonStyle}
                    onClick={() => handlePopoverClick(matchingPosition)}
                  ></Button>
                </Popover>
              );
            })}
          </div>
        )).reverse()
      )}
      <ImageModal
        visible={modalVisible}
        warehouseId={parseInt(warehouseId)}
        modalPosition={modalPosition}
        onClose={() => setModalVisible(false)}
        />
    </Row>
  );
};

export default CorridorMap;
