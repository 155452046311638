import React from "react";
import { Col, Layout, Row } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import LeftMenu from "./LeftMenu";

const { Header, Sider, Content } = Layout;

interface AppLayoutProps {
  children?: React.ReactNode;
}

interface AppLayoutState {
  collapsed: boolean;
  isMobile: boolean;
}

class AppLayout extends React.Component<AppLayoutProps, AppLayoutState> {
  state = {
    collapsed: false,
    isMobile: false, // Track if the view is mobile
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  // Detect if the view is mobile using the onBreakpoint event
  handleBreakpoint = (broken: boolean) => {
    this.setState({ collapsed:broken, isMobile: broken });
  };

  render() {
    const { collapsed, isMobile } = this.state;

    return (
      <Layout style={{ minHeight: "100vh", overflow: "hidden" }}> {/* Ensure full viewport height */}
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth="0"
          breakpoint="lg"
          onBreakpoint={this.handleBreakpoint}
          style={{
            position: isMobile ? "fixed" : "fixed", // Keep Sider fixed in both views
            zIndex: isMobile ? 10 : 1, // Ensure it's on top of the content when mobile
            height: "100vh", // Full viewport height
            width: isMobile ? (collapsed ? "0" : "200px") : "200px", // Default width for desktop
            left: isMobile && collapsed ? "-200px" : "0", // Slide off-screen on mobile
            transition: "all 0.3s ease", // Smooth transition for both mobile and desktop
            overflow: "auto", // Ensures the content inside the Sider is scrollable if needed
          }}
        >
          <div className="logo">
            <img
              src="/logo.png"
              style={{
                maxHeight: "90%",
                maxWidth: "90%",
                borderRadius: "5px",
                background: "#FFFFFF",
                padding: "5px 15px",
              }}
              alt={"Logo"}
            />
          </div>
          <LeftMenu setCollapsed={this.toggle} />
        </Sider>

        <Layout
          className="site-layout"
          style={{ 
            marginLeft: collapsed ? 0 : isMobile ? 0 : 200, // Adjust margin only on desktop
            transition: "margin-left 0.3s ease", // Smooth transition for margin
          }}
        >
          <Header
            style={{ background: "#114373", padding: 0, position: 'fixed', width: '100%', zIndex: 2 }} // Ensure the header stays on top when scrolling
            className="site-layout-background"
          >
            <Row justify="space-between">
              <Col>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: this.toggle,
                    style: {
                      color: "#fff",
                      padding: "0 24px",
                      cursor: "pointer",
                      fontSize: "18px",
                      position: "absolute",
                      left: collapsed ? -10 : !isMobile ? -10 : 185, // Adjust button position
                      top: 20,
                      zIndex: 11,
                      transition: "left 0.3s ease", // Smooth transition for toggle button
                    },
                  }
                )}
              </Col>
            </Row>
          </Header>

          <Content
            className="content"
            style={{
              padding: "64px 12px 12px", // Top padding ensures content doesn't overlap with the fixed header
              minHeight: "100vh",
              overflow: "auto", // Scrollable content
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default AppLayout;
