import React from 'react';
import { Col, notification, Row, Image, Form, Input, Button } from 'antd';
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';

interface LoginPageProps {
  onSuccess?: (token: string) => void;
}

function LoginPage(props: LoginPageProps) {
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    UserService.login(values.username, values.password)
      .then(() => {
          UserService.getDefaultView().then((response) => {
            if (response) {
              navigate("/" + response);
              console.log(response)
            } else {
              navigate("/");
            }
          });
      })
      .catch((error) => {
        if (error.status === 400) {
          notification.error({
            message: <strong>Incorrect Credentials</strong>,
            description: "User or password incorrect.",
            style: { backgroundColor: "#fff2f0", border: "1px solid #ffccc7" },
          });
        } else {
          notification.error({
            message: <strong>Incorrect Credentials</strong>,
            description: "User or password incorrect.",
            style: { backgroundColor: "#fff2f0", border: "1px solid #ffccc7" },
          });
        }
      });
  };

  return (
    <div style={{ height: "100vh", overflow: "hidden", position: "relative" }}>
      <Image
        src="background-image.jpg"
        alt="Background"
        preview={false}
        style={{
          height: "100vh",
          width: "100vw",
          position: "static",
          objectFit: "cover",
        }}
      />
      <div style={{ position: "absolute", top: "25%", width: "100%", zIndex: 1, padding: '0 20px'}}>
        <Row
          gutter={[0, 50]}
          justify="center"
          align="middle"
          style={{ textAlign: 'center' }}
        >
          <Col span={24}>
            <Image src="/login_logo.png" alt="Logo" preview={false} style={{ width: '100%', maxWidth: '200px', margin: '0 auto' }}/>
          </Col>

          <Col span={24}>
            <Form
              name="login-form"
              layout="vertical"
              onFinish={onFinish}
              style={{ maxWidth: "400px", margin: "0 auto" }}
            >
              <Form.Item
                label="User"
                name="username"
                rules={[{ required: true, message: "Please input your username!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Please input your password!" }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    height: "52px",
                    borderRadius: "12px",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>

      <Row justify="start" style={{ position: 'absolute', bottom: 0, width: '100%', padding: '10px 20px', zIndex: 1 }}>
        <Col span={12} sm={6}>
          <Image src="/logo_poweredBy.svg" alt="Powered by" preview={false} style={{ width: '100%', maxWidth: '150px' }}/>
        </Col>
      </Row>
    </div>
  );
}

export default LoginPage;

