import { Input, Col, Row, Space, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import { WarehouseSummary } from "../../../../models/Warehouse";

interface PositionsControlsProps {
  onApply: (
    warehouse: string[],
    countingNumber: string,
    status: string[],
  ) => void;
  warehouses: WarehouseSummary[];
}

const SweepsControls = (props: PositionsControlsProps) => {
  const [warehouses, setWarehouses] = useState<WarehouseSummary[]>([]);
  const [warehouse, setWarehouse] = useState<string[]>([]);
  const [countingNumber, setCountingNumber] = useState<string>("");
  const [status, setStatus] = useState<string[]>([]);

  const onApply = useCallback(() => {
    props.onApply(warehouse, countingNumber, status);
  }, [props, warehouse, countingNumber, status]);

  useEffect(() => {
    onApply();
  }, [onApply]);

  const updateContent = useCallback(() => {
    setWarehouses(props.warehouses)
  }, [props.warehouses]);
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);
  const onWarehouseChange = (values: string[]) => {
    setWarehouse(values);
  };
  const onStatusChange = (values: string[]) => {
    if (values.includes("processing") && !values.includes("creating")) {
      setStatus([...values, "creating"]);
    } else {
      setStatus(values);
    }
  };


  return (
    <Row gutter={[10,10]}>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <span>Warehouse</span>
          <Select
            mode="multiple"
            placeholder="warehouses"
            style={{ width: '100%' }}
            onChange={onWarehouseChange}
            showSearch={false}
            options={warehouses.map((warehouse) => ({
              label: warehouse.name,
              value: warehouse.name,
            }))}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <span>Counting Number</span>
          <Input
            type="text"
            placeholder="Counting Number"
            value={countingNumber}
            onChange={(e) => setCountingNumber(e.target.value)}
            style={{ width: '100%' }}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <span>Status</span>
          <Select
            mode="multiple"
            placeholder="Status"
            style={{ width: '100%' }}
            onChange={onStatusChange}
            showSearch={false}
            options={[
              { value: 'processed', label: 'Processed' },
              { value: 'processing', label: 'Processing' },
              { value: 'stopped', label: 'Cancelled' }
            ]}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default SweepsControls;
