// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-toolbar {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8px;
    border-radius: 8px;
  }
  
  .custom-toolbar-icon {
    color: white;
    font-size: 20px;
    margin-right: 30px;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .custom-toolbar-icon:hover {
    color: #1890ff;
  }
  
  .custom-toolbar-icon:last-child {
    margin-right: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/utils/ImageDisplayer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oCAAoC;IACpC,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,2BAA2B;EAC7B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".custom-toolbar {\n    position: absolute;\n    bottom: 10%;\n    left: 50%;\n    transform: translateX(-50%);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 8px;\n    border-radius: 8px;\n  }\n  \n  .custom-toolbar-icon {\n    color: white;\n    font-size: 20px;\n    margin-right: 30px;\n    cursor: pointer;\n    transition: color 0.2s ease;\n  }\n  \n  .custom-toolbar-icon:hover {\n    color: #1890ff;\n  }\n  \n  .custom-toolbar-icon:last-child {\n    margin-right: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
