export default class RackTemplate {
  id: string = "";
  idx: number = 0;
  template_name: string = "";
  level_range: [number, number] = [0, 0];
  column_range: [number, number] = [0, 0];
  orientation: string = "";
  ignore_cells: Array<[number, number[]]> = [];

  public static from(json: any) {
    return Object.assign(new RackTemplate(), json) as RackTemplate;
  }
}
