import { /*Button,*/ Card, Spin } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import WarehousesTable from "./subcomponents/WarehousesTable";
import WarehouseService from "../../../../services/WarehouseService";
import { WarehouseSummary } from "../../../../models/Warehouse";
// import { PlusOutlined } from "@ant-design/icons";
// import WarehouseCreateModal from "./subcomponents/WarehouseCreate";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../../context/UserContext";
import { useLanguage } from "../../../../context/LanguageContext";

const Warehouses: React.FC = () => {
  const  isMobile  = useContext(UserContext).isMobile;
  const { language } = useLanguage();
  const [warehouses, setWarehouses] = useState<WarehouseSummary[]>([]);
  const [loading, setLoading] = useState(true);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate=useNavigate()

  const updateContent = useCallback(() => {
    WarehouseService.getAll(navigate).then((response) => {
      if (response) {
        setWarehouses(response);
        setLoading(false);
      }
    });
  }, [navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);
  return (
    <>
      <Spin spinning={loading}>
        <Card
          title={language === "es"? "Depósitos" :"Warehouses"}
          style={{
            margin: isMobile ? '10px' : '20px',
            padding: isMobile ? '10px' : '20px',
          }}
        >
          <WarehousesTable warehouses={warehouses} isMobile={isMobile} />
        </Card>
      </Spin>
    </>
  );
};

export default Warehouses;
