import { createContext } from "react";
import  { UserShow } from "../models/User";
interface UserContextType {
    user: UserShow | undefined;
    isMobile: boolean;
  }

const UserContext = createContext<UserContextType >({
    user: undefined,
    isMobile: false,
  });

export default UserContext;
