import React from "react";
import { BsBattery, BsBatteryFull, BsBatteryHalf } from 'react-icons/bs';
import { Card, Col, Divider, Progress, Row, Spin, Statistic, Tag } from "antd";
import { BuildOutlined, ClockCircleOutlined, CodeSandboxOutlined, DatabaseOutlined,  WifiOutlined } from "@ant-design/icons";
import { SupervisionDetails } from "../../../../../../models/Supervision";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useLanguage } from "../../../../../../context/LanguageContext";

interface CorridorDetailsInfoProps {
  supervisiondetails: SupervisionDetails;
  loading: boolean;
}

const SupervisionCorridorDetailsInfo: React.FC<CorridorDetailsInfoProps> = ({ supervisiondetails, loading }) => {
  const { language } = useLanguage();
  const screens = useBreakpoint();
  const connectionStatusTag = (status: string | undefined) => {
    let icon, color;
    switch (status) {
      case "connected":
        icon = <WifiOutlined />;
        color = "#87d068";
        break;

      case "disconnected":
        icon = <WifiOutlined />;
        color = "#f50";
        break;

      case "connecting":
      default:
        icon = <ClockCircleOutlined />;
        color = "processing";
        break;
    }
    return <Tag style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw"}} icon={icon} color={color}></Tag>;
  };

  return (
    <Spin spinning={loading}>
      <Row >
        <Col xs={24} sm={24} md={24} lg={8} style={{ textAlign: 'center' }}>
          <Card bordered={false}>
            <Statistic
              title={<h2 style={{ color: "#434343" }}>{language==="es"?"Posiciones Totales":"Total Positions"}</h2>}
              value={supervisiondetails.total_positions}
            />
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={8}style={{ textAlign: 'center' }}>
          <Card bordered={false}>
            <Statistic
              title={<h2 style={{ color: "#434343" }}>{language==="es"?"Posiciones Leídas":"Readed Positions"}</h2>}
              value={supervisiondetails.read_positions}
            />
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={8} style={{ textAlign: 'center' }} >
          <Card bordered={false}>
            <Statistic
              title={<h2 style={{ color: "#434343" }}>{language==="es"?"Lecturas Efectivas":"Effective Readings"}</h2>}
              value={supervisiondetails.effective_readings}
            />
          </Card>
        </Col>
        {supervisiondetails.hardware_status&&
        <>
        <Divider></Divider>
        <Col span={8}></Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Card
            title={
              <div style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.5vw" : screens.sm ? "1.5vw" : "3vw", textAlign: "center"}}>
                {supervisiondetails.device_name}
              </div>}
          >
            <Row gutter={[10, 10]} justify="center" align="middle" style={{ overflow: "hidden"}} >
              <Col span={6} >
                <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{language==="es"?"Disco":"Disk:"}</b>
              </Col>
              <Col span={4} >
                <Tag style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw"}} icon={<DatabaseOutlined />} color={"#114373"}></Tag>
              </Col>
              <Col span={4}></Col>
              <Col span={10} >

                
                <Progress showInfo={false} percent={Math.round(((supervisiondetails.hardware_status?supervisiondetails.hardware_status.general.disk_status:0) * 100))} strokeLinecap="butt" steps={10} />
              </Col>
              <Col span={6} >
                <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>CPU:</b>
              </Col>
              <Col span={4} >
                <Tag style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw", padding: "-2px" }} icon={<CodeSandboxOutlined />} color={"#114373"} ></Tag>
              </Col>
              <Col span={4}></Col>
              <Col span={10} >
                {supervisiondetails.hardware_status?.general.cpu_status && 
                <Progress showInfo={false} percent={Math.round((supervisiondetails.hardware_status?.general.cpu_status * 100))} strokeLinecap="butt" steps={10} />}
              </Col>
              <Col span={6} >
                <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>RAM:</b>
              </Col>
              <Col span={4} >
                <Tag style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw"}} icon={<BuildOutlined />} color={"#114373"}></Tag>
              </Col>
              <Col span={4}></Col>
              <Col span={10} >
                {supervisiondetails.hardware_status?.general.ram_status && 
                <Progress showInfo={false} percent={Math.round((supervisiondetails.hardware_status?.general.ram_status * 100))} strokeLinecap="butt" steps={10} />}
              </Col>
              <Col span={6} >
                <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{language==="es"?"Conexión":"Connection:"}</b></Col>
              <Col span={4} >
                {connectionStatusTag(supervisiondetails.hardware_status?.connection.connection_status)}
              </Col>
              <Col span={4}></Col>
              <Col span={10} >
                {supervisiondetails?.hardware_status?.connection.signal_strength !== undefined && (
                  <Progress
                    showInfo={false}
                    percent={Math.round((supervisiondetails.hardware_status.connection.signal_strength * 100))}
                    strokeLinecap="butt"
                    steps={10}
                    strokeColor={
                      supervisiondetails.hardware_status.connection.signal_strength > 0.70
                        ? "#52c41a"
                        : supervisiondetails.hardware_status.connection.signal_strength > 0.3
                          ? "#fadb14"
                          : "#f5222d"
                    }
                  />
                )}
              </Col>
              <Col span={6} >
                <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{language==="es"?"Batería":"Battery:"}</b>
              </Col>
              <Col span={4} >
              {supervisiondetails.hardware_status?.battery.battery_level !== undefined ? (
                <Tag style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw"}} 
                icon={
                  supervisiondetails.hardware_status.battery.battery_level < 0.7
                    ? <BsBatteryHalf />
                    : supervisiondetails.hardware_status.battery.battery_level < 0.3
                      ? <BsBattery />
                      : <BsBatteryFull />} 
                color={"#114373"}></Tag>
              ): <Tag style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw"}} 
                icon={<BsBattery />}color={"#114373"}/>}
              </Col>
              <Col span={4}></Col>
              <Col span={10} >
                {supervisiondetails.hardware_status?.battery.battery_level !== undefined && (
                  <Progress
                    percent={parseFloat((supervisiondetails.hardware_status.battery.battery_level * 100).toFixed(2))}
                    strokeLinecap="butt"
                    steps={10}
                    showInfo={false}
                    strokeColor={
                      supervisiondetails.hardware_status.battery.battery_level > 0.70
                        ? "#52c41a"
                        : supervisiondetails.hardware_status.battery.battery_level > 0.3
                          ? "#fadb14"
                          : "#f5222d"
                    }
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}></Col></>}
      </Row>
    </Spin >
  );
};

export default SupervisionCorridorDetailsInfo;




