import { Button, Card, Collapse, Divider, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import RackTemplate from "../../../../models/RackTemplate";
import RackTemplateService from "../../../../services/RackTemplateService";
import RackTemplateColorReferences from "./subcomponents/RackTemplateColorReferences";
import RackTemplateMap from "./subcomponents/RackTemplateMap";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Panel } = Collapse;

const RackTemplates: React.FC = () => {
  const [rackTemplates, setRackTemplates] = useState(new Array<RackTemplate>());
  const [loading, setLoading] = useState(true);

  const groupRackTemplatesByName = (
    rackTemplates: RackTemplate[]
  ): Record<string, RackTemplate> => {
    const groupedRackTemplates: Record<string, RackTemplate> = {};
    rackTemplates.forEach((rackTemplate) => {
      const template_name = rackTemplate.template_name;
      groupedRackTemplates[template_name] = rackTemplate;
    });
    return groupedRackTemplates;
  };

  const updateContent = useCallback(() => {
    RackTemplateService.getAll().then((rackTemplates) => {
      if (rackTemplates == null) {
        return;
      }
      setRackTemplates(rackTemplates);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const groupedRackTemplates = groupRackTemplatesByName(rackTemplates);

  return (
    <>
      <Spin spinning={loading}>
        <Card
          title="Rack Templates"
          extra={
            <Link to={`create`}>
              <Button type="primary" icon={<PlusOutlined />} shape="round">
                Add
              </Button>
            </Link>
          }
        >
          <RackTemplateColorReferences />
          <Divider orientation="center">Corridors</Divider>
          <Collapse defaultActiveKey={Object.keys(groupedRackTemplates)}>
            {Object.entries(groupedRackTemplates).map(
              ([template_name, rackTemplate], index) => (
                <Panel header={template_name} key={template_name}>
                  <Card>
                    <RackTemplateMap rackTemplate={rackTemplate} />
                  </Card>
                </Panel>
              )
            )}
          </Collapse>
        </Card>
      </Spin>
    </>
  );
};

export default RackTemplates;
