import { Input, Col, Row, Space, Checkbox } from "antd";
import { useState, useCallback, useEffect } from "react";

interface PositionsControlsProps {
  onApply: (
    position: string,
    pallet: string,
    corridor: string,
    column: string,
    level: string,
    displayMatcheds: boolean,
    displayUnMatcheds: boolean,
    displayUnexploreds: boolean
  ) => void;
}

const PositionsControls = (props: PositionsControlsProps) => {
  const [position, setPosition] = useState<string>("");
  const [pallet, setPallet] = useState<string>("");
  const [corridor, setCorridor] = useState<string>("");
  const [column, setColumn] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const [displayMatcheds, setDisplayMatcheds] = useState<boolean>(true);
  const [displayUnMatcheds, setDisplayUnMatcheds] = useState<boolean>(false);
  const [displayUnexploreds, setDisplayUnexploreds] = useState<boolean>(false);

  const onApply = useCallback(() => {
    props.onApply(position, pallet, corridor, column, level, displayMatcheds, displayUnMatcheds, displayUnexploreds);
  }, [props, position, pallet, corridor, column, level, displayMatcheds, displayUnMatcheds, displayUnexploreds]);

  useEffect(() => {
    onApply();
  }, [onApply]);

  return (
    <Row gutter={[10, 10]} align="middle" >
      <Col xs={24} sm={12} md={8} lg={4}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Position
          <Input
            placeholder="Position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={8} lg={4}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Pallet
          <Input
            placeholder="Pallet"
            value={pallet}
            onChange={(e) => setPallet(e.target.value)}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={8} lg={4}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Corridor
          <Input
            placeholder="Corridor"
            value={corridor}
            onChange={(e) => setCorridor(e.target.value)}
          />
        </Space>
      </Col>
      <Col xs={12} sm={6} md={4} lg={2}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Column
          <Input
            placeholder="Column"
            value={column}
            onChange={(e) => setColumn(e.target.value)}
          />
        </Space>
      </Col>
      <Col xs={12} sm={6} md={4} lg={2}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Level
          <Input
            placeholder="Level"
            value={level}
            onChange={(e) => setLevel(e.target.value)}
          />
        </Space>
      </Col>
      <Col xs={8} sm={8} md={4} lg={2} >
        <Space direction="vertical" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          Matched
          <Checkbox
            checked={displayMatcheds}
            onChange={(e) => setDisplayMatcheds(e.target.checked)}
          >
          </Checkbox>
        </Space>
      </Col>
      <Col xs={8} sm={8} md={4} lg={2}>
        <Space direction="vertical" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          Unmatched
          <Checkbox
            checked={displayUnMatcheds}
            onChange={(e) => setDisplayUnMatcheds(e.target.checked)}
          >
          </Checkbox>
        </Space>
      </Col>
      <Col xs={8} sm={8} md={4} lg={2}>
        <Space direction="vertical" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          Unexplored
          <Checkbox
            checked={displayUnexploreds}
            onChange={(e) => setDisplayUnexploreds(e.target.checked)}
          >
          </Checkbox>
        </Space>
      </Col>
    </Row>
  );
};

export default PositionsControls;
