import axios, { AxiosResponse } from 'axios';
import  { UserShow } from '../models/User';

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = 'login/';

// TODO: migrate to axios
export default class UserService {
  public static async login(username: string, password: string) {
    try {
      const response = await fetch(API_BASE_URL + API_RESOURCE + "access-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          username: username,
          password: password,
        }),
      });

      if (response.ok) {
        const tokenData = await response.json();
        sessionStorage.setItem("token", tokenData["access_token"]);
        return tokenData["access_token"];
      } else {
        const errorData = await response.json(); // Get error details
        return Promise.reject({
          status: response.status,
          message: errorData?.message || "Login failed",
        });
      }
    } catch (error) {
      return Promise.reject({ status: 500, message: "Server error" });
    }
  }
  

  public static async validateAccessToken() {
    const response = await fetch(API_BASE_URL + API_RESOURCE + "test-token", {
      method: "POST",
      headers: new Headers(UserService.getAuthHeader()),
    });
    if (response.ok) {
      const currentUser = (await response.json()) as Promise<UserShow>;
      return UserShow.from(currentUser);
    } else {
      sessionStorage.removeItem("token");
      return Promise.reject(response);
    }
  }

  public static getAccessToken() {
    return sessionStorage.getItem("token");
  }

  public static getAuthHeader() {
    const token = UserService.getAccessToken();
    if (token) {
      return { Authorization: "Bearer " + token };
    } else {
      return { Authorization: "" };
    }
  }
  public static async getSideBar(): Promise<string[] | null> {
    try {
      const response: AxiosResponse<string[]> = await axios.get(
        API_BASE_URL + "users/sidebar",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async getDefaultView(): Promise<string| null> {
    try {
      const response: AxiosResponse<string> = await axios.get(
        API_BASE_URL +"users/default_view",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
