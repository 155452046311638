import { Button, Card, Col, Divider, Empty, Progress, Row, Spin, Statistic, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SupervisionService from "../../../../services/SupervisionService";
import { SupervisionWarehouse } from "../../../../models/Supervision";
import { InfoCircleOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import UserContext from "../../../../context/UserContext";

const Supervisions: React.FC = () => {
  const [supervisionWarehouses, setsupervisionWarehouses] = useState<SupervisionWarehouse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const screens = useBreakpoint();
  const isMobile=useContext(UserContext)


  useEffect(() => {
    SupervisionService.getSupervisionWarehouses()
      .then((supervisionWarehouse) => {
        setsupervisionWarehouses(supervisionWarehouse || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch warehouses:", error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Card title={"Pallet Supervision"}
      style={{
        margin: isMobile ? '10px' : '20px',
        padding: isMobile ? '10px' : '20px',
      }}>
        <Spin spinning={loading}>
          <Row gutter={[10, 10]}>
            {supervisionWarehouses.map((supervisionWarehouse) => (
              <Col
                xs={24} sm={24} md={12} lg={12}xl={8} // Adjusts columns based on screen size
                key={supervisionWarehouse.warehouse_idx}
              >
                <Card
                  title={supervisionWarehouse.warehouse_name}
                  extra={
                    supervisionWarehouse.is_processing && (
                      <Link
                        to={`${supervisionWarehouse.warehouse_idx}`}
                        state={{ supervisionWarehouse }}
                      >
                        <Button
                          type="primary"
                          shape="round"
                          icon={<InfoCircleOutlined />}
                        >
                          Details
                        </Button>
                      </Link>
                    )
                  }
                >
                  {supervisionWarehouse.is_processing ? (
                    <Row gutter={[10, 10]}>
                      <Col
                        xs={24} sm={12} md={12} lg={12}
                        style={{ textAlign: "center" }}
                      >
                        <Card bordered={false}>
                          <Statistic
                            title={<h3>Counting number</h3>}
                            value={supervisionWarehouse.counting_number}
                            groupSeparator=""
                          />
                        </Card>
                      </Col>
                      <Col
                        xs={24} sm={12} md={12} lg={12}
                        style={{ textAlign: "center" }}
                      >
                        <Card bordered={false}>
                          <Statistic
                            title={<h3>Devices counting</h3>}
                            value={supervisionWarehouse.devices_count}
                          />
                        </Card>
                      </Col>
                      <Col
                        xs={24} sm={12} md={12} lg={12}
                        style={{ textAlign: "center" }}
                      >
                        <Card title={"Readings"}>
                          <Row gutter={[10, 10]}>
                            <Col span={24}>
                              <Progress
                                type="circle"
                                percent={parseFloat(
                                  (
                                    supervisionWarehouse.reading_percentage *
                                    100
                                  ).toFixed(2)
                                )}
                                format={(percent) => `${percent}%`}
                                size={screens.xs ? 60 : 80} // Adjust size for mobile
                                success={{ progress: -1 }}
                              />
                            </Col>
                            <Col span={24}>
                              <b
                                style={{
                                  fontSize: screens.lg ? "0.90vw" : screens.md ? "1.5vw" : screens.sm ? "2vw" : "3vw",
                                }}
                              >{`${supervisionWarehouse.read_positions} / ${supervisionWarehouse.total_count}`}</b>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col
                        xs={24} sm={12} md={12} lg={12}
                        style={{ textAlign: "center" }}
                      >
                        <Card title={"Effective"}>
                          <Row gutter={[10, 10]}>
                            <Col span={24}>
                              <Progress
                                type="circle"
                                percent={parseFloat(
                                  (
                                    supervisionWarehouse.effective_percentage *
                                    100
                                  ).toFixed(2)
                                )}
                                format={(percent) => `${percent}%`}
                                size={screens.xs ? 60 : 80} // Adjust size for mobile
                                strokeColor={
                                  supervisionWarehouse.effective_percentage >
                                    0.75
                                    ? "#1677ff"
                                    : supervisionWarehouse.effective_percentage >
                                      0.50
                                      ? "#fadb14"
                                      : "#f5222d"
                                }
                              />
                            </Col>
                            <Col span={24}>
                              <b
                                style={{
                                  fontSize: screens.lg ? "0.90vw" : screens.md ? "1.5vw" : screens.sm ? "2vw" : "3vw",
                                }}
                              >{`${supervisionWarehouse.effective_readings} / ${supervisionWarehouse.read_positions}`}</b>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  ) : (
                    <Empty
                      imageStyle={{ height: "30.5vh", marginBottom: 0 }}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <Typography.Title level={4}>
                          Currently not being processed
                        </Typography.Title>
                      }
                    />
                  )}
                  <Divider />
                  <Row justify={"center"}>
                    <Link to={`history/${supervisionWarehouse.warehouse_idx}`}>
                      <Button type="link">Supervision History</Button>
                    </Link>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </Spin>
      </Card>
    </>
  );
};

export default Supervisions;
