import axios, { AxiosResponse } from "axios";
import UserService from "./UserService";
import { CorridorBase, CorridorShow } from "../models/Corridor";
const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "corridors/";
export default class CorridorService {
  public static async getAll(): Promise<CorridorBase[] | null> {
    try {
      const response: AxiosResponse<CorridorBase[]> = await axios.get(
        API_BASE_URL + API_RESOURCE,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async getOne(id: string): Promise<CorridorShow | null> {
    try {
      const response: AxiosResponse<CorridorShow> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + "/",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async create(
    corridor_base: CorridorBase
  ): Promise<CorridorBase | null> {
    try {
      const response: AxiosResponse<CorridorBase> = await axios.post(
        API_BASE_URL + API_RESOURCE,
        corridor_base,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
