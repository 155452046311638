import { DownloadOutlined, RedoOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Image, Spin } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

// Define a cache object
const imageCache: Record<string, string | undefined> = {};
const lowImageCache: Record<string, string | undefined> = {};

interface ImageDisplayerProps {
  fetchFunction: () => Promise<Blob | null>;
  lowfetchFuntion?: () => Promise<Blob | null>;
  style?: React.CSSProperties;
  rotate?: boolean;
  height?: string;
  width?: string;
  positionLabel: string;  // Added this prop to uniquely identify each image
}

function ImageDisplayer2(props: ImageDisplayerProps) {
  const [image, setImage] = useState<string | undefined>(imageCache[props.positionLabel]);
  const [lowImage, setLowImage] = useState<string | undefined>(lowImageCache[props.positionLabel]);
  const [loadingLowImage, setLoadingLowImage] = useState<boolean>(true);
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [lastMousePosition, setLastMousePosition] = useState<{ x: number; y: number } | null>(null);
  const [rotation, setRotation] = useState<number>(0); // Rotation state
  const imgRef = useRef<HTMLDivElement>(null); // Reference for the image container

  const defaultStyle: React.CSSProperties = {
    borderRadius: "10px",
    textAlign: "center",
  };

  const updatelowImage = useCallback(async () => {
    if (props.lowfetchFuntion && !lowImageCache[props.positionLabel]) {
      setLoadingLowImage(true);
      const imageBlob = await props.lowfetchFuntion();
      if (imageBlob === null) {
        setLoadingLowImage(false);
        return;
      }
      const imageUrl = URL.createObjectURL(imageBlob);
      setLowImage(imageUrl);
      lowImageCache[props.positionLabel] = imageUrl; // Cache the image
      setLoadingLowImage(false);
      return () => URL.revokeObjectURL(imageUrl);
    } else if (lowImageCache[props.positionLabel]) {
      setLowImage(lowImageCache[props.positionLabel]);
      setLoadingLowImage(false);
    }
  }, [props]);

  const updateImage = useCallback(async () => {
    if (!imageCache[props.positionLabel]) {
      const imageBlob = await props.fetchFunction();
      if (imageBlob === null) return;
      const imageUrl = URL.createObjectURL(imageBlob);
      setImage(imageUrl);
      imageCache[props.positionLabel] = imageUrl; // Cache the image
      return () => URL.revokeObjectURL(imageUrl);
    } else {
      setImage(imageCache[props.positionLabel]);
    }
  }, [props]);

  useEffect(() => {
    updatelowImage();
  }, [updatelowImage]);

  const onDownload = (imgUrl: string | undefined) => {
    if (imgUrl === undefined) {
      return;
    }
    const link = document.createElement<'a'>('a');
    link.href = imgUrl;
    link.download = 'image.jpg';
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.2, 2)); // Max zoom level is 2x
  };

  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.2, 1)); // Min zoom level is 1x
  };

  const handleWheelZoom = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaY < 0) {
      handleZoomIn();
    } else {
      handleZoomOut();
    }
  };

  // Dragging handlers
  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setLastMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !lastMousePosition) return;

    const deltaX = e.clientX - lastMousePosition.x;
    const deltaY = e.clientY - lastMousePosition.y;

    setPosition((prev) => ({
      x: prev.x + deltaX,
      y: prev.y + deltaY,
    }));

    setLastMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setLastMousePosition(null);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    setLastMousePosition(null);
  };
  const handleRotate = () => setRotation((prev) => prev + 90);
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {loadingLowImage && (
        <Spin
          spinning={loadingLowImage}
          style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        />
      )}
      <Image
        src={lowImage}
        style={{
          ...defaultStyle,
          ...props.style,
          opacity: loadingLowImage ? 0 : 1, // Hide image while loading
          transition: "opacity 0.3s ease-in-out", // Smooth transition
        }}
        onLoad={() => setLoadingLowImage(false)} // Stop loading when the image is loaded
        preview={{
          toolbarRender: () => (
            <div className="custom-toolbar">
              {/* Zoom In Button */}
              <ZoomInOutlined className="custom-toolbar-icon" onClick={handleZoomIn} />
              {/* Zoom Out Button */}
              <ZoomOutOutlined className="custom-toolbar-icon" onClick={handleZoomOut} />
              {/* Rotate Button */}
              <RedoOutlined className="custom-toolbar-icon" onClick={handleRotate} />
              {/* Custom Download Button */}
              <DownloadOutlined className="custom-toolbar-icon" onClick={() => onDownload(image)} />
            </div>
          ),
          destroyOnClose: true,
          onVisibleChange: (visible) => {
            if (!visible) {
              if (image) {
                setTimeout(() => {
                  URL.revokeObjectURL(image);
                  setImage(undefined); // Clean up the state after the preview is closed
                }, 100); // Slight delay to ensure no premature revoking
              }
              setZoomLevel(1);
              setPosition({
                x: 0,
                y: 0
              });
              setRotation(0)
            }
            else {
              updateImage();
              setZoomLevel(0.7);
              setPosition({
                x: 0,
                y: 0
              });
            }
          },
          imageRender: () =>
            image ? (
              <div
                ref={imgRef}
                onWheel={handleWheelZoom}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave} // Handle when the mouse leaves the image
                style={{
                  transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
                  transition: isDragging ? "none" : "transform 0.3s ease-in-out", // No smooth transition while dragging
                  cursor: isDragging ? "grabbing" : "grab",
                  userSelect: "none", // Prevents text/image selection on double-click
                }}
              >
                <Image src={image} preview={false} style={{
                  transform: `rotate(${rotation}deg) scale(${zoomLevel})`, // Apply zoom and rotation
                  pointerEvents: "none", // Disable interaction with the image itself while keeping the container interactive
                }} />
              </div>
            ) : (
              <Spin spinning={true} />
            ),
        }}
        height={props.height}
        width={props.width}
      />
    </div>
  );
}

export default ImageDisplayer2;
