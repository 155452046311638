import { Link, useParams } from "react-router-dom";
import { Card, Col, Row, List, notification, Button, Input, Space, Divider, Spin } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import {  VideoPosition } from "../../../../../models/Sweep";
import { CaretLeftOutlined, PlayCircleOutlined, ReloadOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import VideoRevisionService from "../../../../../services/VideoRevisionService";

type PaginationPosition = 'top' | 'bottom' | 'both';
type PaginationAlign = 'start' | 'center' | 'end';

const SweepRevision = () => {
  const { sweepId } = useParams();
  const [positions, setPositions] = useState<VideoPosition[]>([]);
  const [position] = useState<PaginationPosition>('bottom');
  const [align] = useState<PaginationAlign>('center');
  const [positionFilter, setPositionFilter] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [showRestartButton, setShowRestartButton] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false); // Collapsible state
  const videoRef = useRef<HTMLVideoElement>(null);

  const updateContent = useCallback(() => {
    setLoading(true);
    if (sweepId) {
      VideoRevisionService.getPositions(sweepId).then((positions) => {
        if (positions == null) {
          setLoading(false);
          return;
        }
        setPositions(positions);
        setLoading(false);
      });
    }
  }, [sweepId]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const data = positions.filter((entry) =>
    entry.position_label.toLocaleUpperCase().includes(positionFilter.toLocaleUpperCase())
  );

  const fetchVideo = async (sweepId: string, idx: string) => {
    notification.info({ message: "Loading Video", duration: 1 });
    const video = await VideoRevisionService.getVideo(sweepId, idx);
    if (video) {
      setVideoUrl(video);
      setLoading(false);
      notification.success({ message: "Video loaded", duration: 2 });
      setShowRestartButton(false);
    } else {
      setLoading(false);
      notification.error({ message: "Failed to load video", duration: 2 });
    }
  };

  const restartVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setShowRestartButton(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Card
        title="Video Revision"
        style={{
          height: "100%", margin: '10px',
          padding: '10px'
        }}
        extra={<Link to={`/sweeps/${sweepId}`} >
          <Button
            type="primary"
            shape="round"
            icon={<CaretLeftOutlined />}
          >
            Back
          </Button>
        </Link>}
      >
        <Row gutter={[10, 0]} style={{ width: "100%" }}>
          <Col span={collapsed ? 23 : 17}>
            <div style={{ position: "relative" }}>
              {showRestartButton && (
                <Button
                  type="dashed"
                  size="large"
                  icon={<ReloadOutlined style={{ fontSize: "2.5em" }} />}
                  onClick={restartVideo}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                    border: "none",
                    borderRadius: "50px",
                    width: "100px",
                    height: "100px",
                  }}
                />
              )}
              <video
                ref={videoRef}
                autoPlay
                width="100%"
                onPlay={() => setShowRestartButton(false)}
                controls
                src={videoUrl}
                onEnded={() => setShowRestartButton(true)}
                style={{ backgroundColor: "black", height: "82vh" }}
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>

          <Col span={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Divider type="vertical" style={{ height: "100%" }} />
            <Button
              type="link"
              shape="circle"
              icon={collapsed ?  <MenuFoldOutlined />: <MenuUnfoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{ margin: "10px 0" }}
            />
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>

          <Col span={collapsed ? 0 : 6}>
            <Space style={{ marginLeft: "10px" }}>Search:
              <Input
                allowClear
                placeholder="Position"
                value={positionFilter}
                onChange={(e) => setPositionFilter(e.target.value)}
                style={{ width: "65%" }}
              />
            </Space>
            <Divider />
            <List
              pagination={{ position, align, defaultPageSize: 10 }}
              size="small"
              header={<div style={{ fontSize: window.innerWidth < 900 ? "3vw" : "1vw" }}>Videos</div>}
              bordered
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  onClick={() => fetchVideo(sweepId!, item.idx.toString())}
                  style={{
                    cursor: "pointer",
                    transition: "background 0.3s",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "")}
                >
                  <Row gutter={[0, 0]} style={{ textAlign: "center", width: "100%" }}>
                    <Col span={12} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                      <Row>
                        <Col span={24} style={{ textAlign: "left" }}>
                          {item.position_label}
                        </Col>
                        <Col span={24} style={{ textAlign: "left" }}>
                          <div style={{ fontSize: window.innerWidth < 900 ? "3vw" : "80%", color: "#7a848d" }}>
                            {item.status}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                      <Button
                        type="link"
                        shape="round"
                        size="large"
                        icon={<PlayCircleOutlined />}
                        style={{ borderRadius: "100px", pointerEvents: "none" }}
                      />
                    </Col>
                  </Row>

                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default SweepRevision;
