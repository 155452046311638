import { Input, Col, Row, Space, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import { WarehouseSummary } from "../../../../models/Warehouse";
import { useLanguage } from "../../../../context/LanguageContext";

interface PositionsControlsProps {
  onApply: (
    warehouse: string[],
    countingNumber: string,
    status: string[],
  ) => void;
  warehouses: WarehouseSummary[];
  loading: boolean;
}

const SweepsControls = (props: PositionsControlsProps) => {
  const { language } = useLanguage();
  const [warehouses, setWarehouses] = useState<WarehouseSummary[]>([]);
  const [warehouse, setWarehouse] = useState<string[]>([]);
  const [debouncedWarehouse, setDebouncedWarehouse] = useState<string[]>([]);
  const [countingNumber, setCountingNumber] = useState<string>("");
  const [debouncedCountingNumber, setDebouncedCountingNumber] = useState<string>("");
  const [status, setStatus] = useState<string[]>([]);
  const [debouncedStatus, setDebouncedStatus] = useState<string[]>([]);

  const onApply = useCallback(() => {
    props.onApply(debouncedWarehouse, debouncedCountingNumber, debouncedStatus);
    //eslint-disable-next-line
  }, [debouncedWarehouse, debouncedCountingNumber, debouncedStatus]);

  useEffect(() => {
    onApply();
    //eslint-disable-next-line
  }, [debouncedWarehouse, debouncedCountingNumber, debouncedStatus]);

  useEffect(() => {
    const updateContent = () => setWarehouses(props.warehouses);
    updateContent();
    const interval = setInterval(updateContent, 600000);
    return () => clearInterval(interval);
  }, [props.warehouses]);

  // Debounce logic for countingNumber
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedCountingNumber(countingNumber);
    }, 1000); // Wait 0.75 seconds before updating
    return () => clearTimeout(handler); // Cleanup timeout on each change
  }, [countingNumber]);

  // Debounce logic for warehouse
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedWarehouse(warehouse);
    }, 1250); // Wait 0.75 seconds before updating
    return () => clearTimeout(handler); // Cleanup timeout on each change
  }, [warehouse]);

  // Debounce logic for status
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedStatus(status);
    }, 1000); // Wait 0.75 seconds before updating
    return () => clearTimeout(handler); // Cleanup timeout on each change
  }, [status]);

  const onWarehouseChange = (values: string[]) => {
    setWarehouse(values);
  };

  const onStatusChange = (values: string[]) => {
    if (values.includes("processing") && !values.includes("creating")) {
      setStatus([...values, "creating"]);
    } else {
      setStatus(values);
    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Depósitos" : "Warehouses"}</span>
          <Select
            disabled={props.loading}
            mode="multiple"
            placeholder={language === "es" ? "Depósitos" : "Warehouses"}
            style={{ width: "100%" }}
            onChange={onWarehouseChange}
            showSearch={false}
            options={warehouses.map((warehouse) => ({
              label: warehouse.name,
              value: warehouse.idx,
            }))}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Número de Conteo" : "Counting Number"}</span>
          <Input
            disabled={props.loading}
            type="text"
            allowClear
            placeholder={language === "es" ? "Número de Conteo" : "Counting Number"}
            value={countingNumber}
            onChange={(e) => setCountingNumber(e.target.value)}
            style={{ width: "100%" }}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Estado" : "Status"}</span>
          <Select
            disabled={props.loading}
            mode="multiple"
            placeholder={language === "es" ? "Estado" : "Status"}
            style={{ width: "100%" }}
            onChange={onStatusChange}
            showSearch={false}
            options={[
              { value: "processed", label: language === "es" ? "Procesado" : "Processed" },
              { value: "processing", label: language === "es" ? "En curso" : "Processing" },
              { value: "stopped", label: language === "es" ? "Cancelado" : "Cancelled" },
            ]}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default SweepsControls;
