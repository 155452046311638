import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Card, Spin, Divider } from "antd";
import CorridorMap from "./CorridorMap";
import CorridorColorReferences from "./CorridorColorReferences";
import { CorridorSummary } from "../../../../../../models/Corridor";
import SweepService from "../../../../../../services/SweepService";
import WarehouseService from "../../../../../../services/WarehouseService";
import { useNavigate } from "react-router-dom";

const { Panel } = Collapse;

interface CorridorCollapseProps {
  sweepId: string;
}

const SweepMap: React.FC<CorridorCollapseProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [corridors, setCorridors] = useState<CorridorSummary[]>([]);
  const [sweepId] = useState<string>(props.sweepId);
  const navigate=useNavigate()
  const updateContent = useCallback(() => {
    // setLoading(true);
    SweepService.getOne(sweepId,navigate).then((sweep) => {
      if (sweep === null) {
        return;
      };
      if (sweep.warehouse){
        WarehouseService.getOne(sweep.warehouse.idx.toString(),navigate).then((warehouse) => {
          if (warehouse && warehouse.corridors) {
            setCorridors(warehouse.corridors);

            console.log(warehouse.corridors);
          
          setLoading(false);
          }
        });
      }
    });
  }, [sweepId,navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 15*60000);
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <Spin spinning={loading}>
      <CorridorColorReferences />
      <Divider orientation="center">Corridors</Divider>
      <Collapse accordion destroyInactivePanel>
        {corridors.map((corridor) => (
          <Panel header={`Corridor ${corridor.name}`} key={corridor.name}>
            <Card>
              <CorridorMap
                sweepId={props.sweepId}
                corridorId={corridor.idx}
              />
            </Card>
          </Panel>
        ))}
      </Collapse>
    </Spin>
  );
};

export default SweepMap;
