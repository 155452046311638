import { Button, Card, Col, Empty, Progress, Row, Spin, Statistic, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SupervisionService from "../../../../../../services/SupervisionService";
import { SupervisionHistory } from "../../../../../../models/Supervision";
import { CaretLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import UserContext from "../../../../../../context/UserContext";

const SupervisionsHistory: React.FC = () => {
  const [supervisionSweeps, setSupervisionSweeps] = useState<SupervisionHistory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { warehouseIdx } = useParams();
  const screens = useBreakpoint();
  const isMobile=useContext(UserContext)

  const updateContent = useCallback(() => {
  }, []);
  const navigate=useNavigate()
  useEffect(() => {
    SupervisionService.getSupervisionHistories(Number(warehouseIdx),navigate)
      .then((supervisionSweep) => {
        setSupervisionSweeps(supervisionSweep || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch sweeps:", error);
        setLoading(false);
      });
  }, [warehouseIdx,navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 180000);
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <>
      <Card
      style={{
        margin: isMobile ? '10px' : '20px',
        padding: isMobile ? '10px' : '20px',
      }}
        title={"Supervision History"}
        extra={<Link to={`/supervision`} >
        <Button
          type="primary"
          shape="round"
          icon={<CaretLeftOutlined />}
        >
          Back
        </Button>
      </Link>}
      >
        <Spin spinning={loading}>
          {supervisionSweeps.length === 0 ? (
            <Empty
              imageStyle={{ height: 260, marginBottom: 0 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <Typography.Title level={4}>
                  No History Available
                </Typography.Title>
              }
            />
          ) : (
            <Row gutter={[10, 10]}>
              {supervisionSweeps.map((supervisionSweep) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <Card
                    title={screens.lg ? <>{supervisionSweep.warehouse_name}: {supervisionSweep.counting_number}</> 
                    : <>{supervisionSweep.warehouse_name}</>}
                    extra={
                      <Link to={`/supervision/history/${supervisionSweep.warehouse_idx}/${supervisionSweep.sweep_idx}`}>
                        <Button type="primary"
                          shape="round" ><InfoCircleOutlined />Details</Button>
                          
                      </Link>
                    }>
                    {supervisionSweep.is_processing === false ? <Row gutter={[10, 10]}>
                      <Col 
                        xs={24} sm={12} md={12} lg={12} style={{ textAlign: 'center' }} >
                        <Card bordered={false}>
                          <Statistic
                            title={<h3>Counting number </h3>}
                            value={supervisionSweep.counting_number}
                            groupSeparator=""
                          />
                        </Card>
                      </Col>
                      <Col 
                        xs={24} sm={12} md={12} lg={12} style={{ textAlign: 'center' }} >
                        <Card bordered={false}>
                          <Statistic
                            title={<h3>Devices counting </h3>}
                            value={supervisionSweep.devices_count}
                          />
                        </Card>
                      </Col>
                      <Col 
                        xs={24} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                        <Card title={"Readings"}>
                          <Row gutter={[10, 10]}>
                            <Col span={24}>
                              <Progress type="circle" percent={parseFloat((supervisionSweep.reading_percentage * 100).toFixed(2))}
                                format={(percent) => `${percent}%`} size={80} success={{ progress: -1 }} />
                            </Col>
                            <Col span={24}>
                              <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.5vw" : screens.sm ? "2vw" : "3vw" }}>{`${supervisionSweep.read_positions} / ${supervisionSweep.total_count}`}</b>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col 
                        xs={24} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                        <Card title={"Effective"}>
                          <Row gutter={[10, 10]}>
                            <Col span={24}>
                              <Progress type="circle" percent={parseFloat((supervisionSweep.effective_percentage * 100).toFixed(2))}
                                format={(percent) => `${percent}%`} size={80}
                                strokeColor={supervisionSweep.effective_percentage > 0.75 ? "#1677ff" : supervisionSweep.effective_percentage > 0.50 ? "#fadb14" : "#f5222d"} />
                            </Col>
                            <Col span={24}>
                              <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.5vw" : screens.sm ? "2vw" : "3vw" }}>{`${supervisionSweep.effective_readings} / ${supervisionSweep.read_positions}`}</b>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row> : <Empty imageStyle={{ height: 260, marginBottom: 0 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                      <Typography.Title level={4}>
                        Not History Available
                      </Typography.Title>
                    } ></Empty>}
                  </Card>
                </Col>)
              )}
            </Row>)}

        </Spin>
      </Card >
    </>
  );
};

export default SupervisionsHistory;
