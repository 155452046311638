import { DownloadOutlined, RedoOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Image, Spin } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import './ImageDisplayer.css';
interface ImageDisplayerProps {
  fetchFunction: () => Promise<Blob | null>;
  lowfetchFuntion?: (abortSignal: AbortSignal) => Promise<Blob | null>;
  style?: React.CSSProperties;
  rotate?: boolean;
  height?: string;
  width?: string;
}

function ImageDisplayer(props: ImageDisplayerProps) {
  const [image, setImage] = useState<string | undefined>(undefined);
  const [lowImage, setLowImage] = useState<string | undefined>(undefined);
  const [loadingLowImage, setLoadingLowImage] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [lastMousePosition, setLastMousePosition] = useState<{ x: number; y: number } | null>(null);
  const [rotation, setRotation] = useState<number>(0); // Rotation state
  const controllerRef = useRef<AbortController | null>(null);
  const imgRef = useRef<HTMLDivElement>(null); // Reference for the image container
  const [dragging, setDragging] = useState(false);
  const startPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const currentPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const updatelowImage = useCallback(async () => {
    if (!props.lowfetchFuntion) return;

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    setLoadingLowImage(true);

    try {
      const imageBlob = await props.lowfetchFuntion(controller.signal);
      if (imageBlob === null || controller.signal.aborted) {
        return;
      }

      const imageUrl = URL.createObjectURL(imageBlob);
      setLowImage(imageUrl);

      return () => {
        URL.revokeObjectURL(imageUrl);
      };
    } catch (error) {
      console.error(error);
    } finally {
      if (!controller.signal.aborted) {
        setLoadingLowImage(false);
      }
    }
  }, [props.lowfetchFuntion]);

  const updateImage = useCallback(async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    try {
      const imageBlob = await props.fetchFunction();
      if (imageBlob === null || controller.signal.aborted) return;

      const imageUrl = URL.createObjectURL(imageBlob);
      setImage(imageUrl);

      return () => URL.revokeObjectURL(imageUrl);
    } catch (error) {
      console.error(error);
    }
  }, [props.fetchFunction]);

  useEffect(() => {
    updatelowImage();
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [updatelowImage]);

  const onDownload = (imgUrl: string | undefined) => {
    if (!imgUrl) return;

    const link = document.createElement("a");
    link.href = imgUrl;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handlePreviewClose = () => {
    if (image) {
      setTimeout(() => {
        URL.revokeObjectURL(image);
        setImage(undefined); // Clean up the state after the preview is closed
      }, 100); // Slight delay to ensure no premature revoking
    }
    setZoomLevel(1);
    setPosition({
      x: 0,
      y: 0
    });
    setRotation(0)

  };

  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.2, 2)); // Max zoom level is 2x
  };

  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.2, 0.7)); // Min zoom level is 1x
  };

  const handleWheelZoom = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaY < 0) {
      handleZoomIn();
    } else {
      handleZoomOut();
    }
  };

  // Dragging handlers
  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setLastMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !lastMousePosition) return;

    const deltaX = e.clientX - lastMousePosition.x;
    const deltaY = e.clientY - lastMousePosition.y;

    setPosition((prev) => ({
      x: prev.x + deltaX,
      y: prev.y + deltaY,
    }));

    setLastMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setLastMousePosition(null);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    setLastMousePosition(null);
  };
  const handleRotate = () => setRotation((prev) => prev + 90);
  const handleDragStart = (event: React.MouseEvent | React.TouchEvent) => {
    setDragging(true);
    
    // Handle touch and mouse
    const { clientX, clientY } = 'touches' in event ? event.touches[0] : event;
    startPos.current = { x: clientX, y: clientY };
  };
  
  const handleDragMove = (event: React.MouseEvent | React.TouchEvent) => {
    if (!dragging) return;
  
    // Handle touch and mouse
    const { clientX, clientY } = 'touches' in event ? event.touches[0] : event;
    
    const deltaX = clientX - startPos.current.x;
    const deltaY = clientY - startPos.current.y;
  
    // Update the position based on the delta movement
    setPosition((prevPosition) => ({
      x: prevPosition.x + deltaX,
      y: prevPosition.y + deltaY,
    }));
  
    // Update the start position for the next move
    startPos.current = { x: clientX, y: clientY };
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {loadingLowImage && (
        <Spin
          spinning={loadingLowImage}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
      <Image
        src={lowImage}
        style={{
          borderRadius: "10px",
          textAlign: "center",
          ...props.style,
          opacity: loadingLowImage ? 0 : 1,
          transition: "opacity 0.3s ease-in-out",
        }}
        onLoad={() => setLoadingLowImage(false)}
        preview={{
          toolbarRender: () => (
            <div className="custom-toolbar">
              {/* Zoom In Button */}
              <ZoomInOutlined className="custom-toolbar-icon" onClick={handleZoomIn} />
              {/* Zoom Out Button */}
              <ZoomOutOutlined className="custom-toolbar-icon" onClick={handleZoomOut} />
              {/* Rotate Button */}
              <RedoOutlined className="custom-toolbar-icon" onClick={handleRotate} />
              {/* Custom Download Button */}
              <DownloadOutlined className="custom-toolbar-icon" onClick={() => onDownload(image)} />
            </div>
          ),
          destroyOnClose: true,
          onVisibleChange: (visible) => {
            if (!visible) handlePreviewClose();
            else {
              updateImage();
              setZoomLevel(0.7);
              setPosition({
                x: 0,
                y: 0
              });
            }
          },
          imageRender: () =>
            image ? (
              <div
                ref={imgRef}
                onWheel={handleWheelZoom}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave} // Handle when the mouse leaves the image
                onTouchStart={handleDragStart}
                onTouchMove={handleDragMove}
                onTouchEnd={handleDragEnd}
                style={{
                  transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
                  transition: isDragging ? "none" : "transform 0.3s ease-in-out", // No smooth transition while dragging
                  cursor: isDragging ? "grabbing" : "grab",
                  userSelect: "none", // Prevents text/image selection on double-click
                }}
              >
                <Image src={image} preview={false} style={{
                  transform: `rotate(${rotation}deg) scale(${zoomLevel})`, // Apply zoom and rotation
                  pointerEvents: "none", // Disable interaction with the image itself while keeping the container interactive
                }} />
              </div>
            ) : (
              <Spin spinning={true} />
            ),
        }}
        height={props.height}
        width={props.width}
      />
    </div>
  );
}

export default ImageDisplayer;
