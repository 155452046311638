import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Tag,
  Space,
  Modal,
  Input,
  Row,
  notification,
  Checkbox,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import SweepService from "../../../../../services/SweepService";
import { ReadingUpdateModel } from "../../../../../models/Sweep";
import { useNavigate } from "react-router-dom";

interface EditableLabelProps {
  prev_position_label: string;
  label: string;
  labelType: string;
  isEmpty: boolean;
  repeated: boolean;
  onUpdateContent: () => void;
  previous?: string | null;
  next?: string | null;
  sweepId?: string | null;
  tab?: string;
  editable?: boolean
}


function EditableLabel(props: EditableLabelProps) {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [newLabel, setNewLabel] = useState<string>(props.label);
  const [isEmpty, setIsEmpty] = useState<boolean>(props.isEmpty);
  const navigate = useNavigate()
  useEffect(() => {
    setNewLabel(props.label);
  }, [props.label]);

  useEffect(() => {
    setIsEmpty(props.isEmpty);
  }, [props.isEmpty]);

  useEffect(() => {
    if (showEditModal) {
      return;
    }
  }, [showEditModal]);

  const onEditClick = useCallback(() => {
    setShowEditModal(true);
  }, []);

  const handleCancel = useCallback(() => {
    setShowEditModal(false);
  }, []);

  const onOk = useCallback(() => {
    const palPattern: RegExp = /\d{20,}$/;
    const reading = new ReadingUpdateModel();
    reading.position_label = props.prev_position_label;
    reading.label = newLabel.toUpperCase();
    reading.is_empty = isEmpty;
    reading.sweep_idx = props.sweepId ? parseInt(props.sweepId) : 1;
    console.log("ESTADO DE POSICION", isEmpty)

    let message = "";
    if (!reading.is_empty) {
      if (!palPattern.test(reading.label)) {
        message = "INVALID FORMAT";
      }
    }

    if (message !== "INVALID FORMAT") {
      console.log(reading)
      SweepService.updateReading(reading).then((res) => {
        if (res) {
          notification.success({
            message: "Label edited successfully",
            description: res,
          });
        }
        props.onUpdateContent();
        if (props.next && props.sweepId) {
          navigate("/sweeps/" + props.sweepId + "/revision/?tab=" + props.tab + "&positionLabel=" + props.next)
        }
        else {
          if (props.sweepId && props.previous) {
            navigate("/sweeps/" + props.sweepId + "/revision/?tab=" + props.tab + "&positionLabel=" + props.previous)
          }
        }
        setNewLabel(reading.label);
        setIsEmpty(reading.is_empty);
        setShowEditModal(false)
      });
    } else {
      notification.error({
        message: "VALIDATION ERROR",
        description: "INVALID FORMAT",
      });
      setNewLabel(props.label);
      setIsEmpty(props.isEmpty);
      setShowEditModal(false);
    }
  }, [newLabel, props, isEmpty, navigate]);

  return (
    <Space direction="horizontal">
      <Tag color={props.repeated ? "error" : "default"}>{(props.label !== "" && props.label !== null) ? props.label.toUpperCase() : ""}</Tag>
      {props.editable !== false && <Button
        type="primary"
        size="small"
        icon={<EditOutlined />}
        onClick={onEditClick}
      />}
      <Modal
        title="Edit Label"
        open={showEditModal}
        onOk={onOk}
        onCancel={handleCancel}
      >
        <Row justify={"center"}>
          <Space direction="horizontal" align="center" size={"large"}>
            <Input
              placeholder="New label"
              value={newLabel.toUpperCase()}
              onChange={(e) => setNewLabel(e.target.value)}
              disabled={props.labelType === "pallet" && isEmpty}
            />
            {props.labelType === "pallet" && (
              <Checkbox
                checked={isEmpty}
                onChange={(e) => setIsEmpty(e.target.checked)}
              >
                Is Empty
              </Checkbox>
            )}
          </Space>
        </Row>
      </Modal>
    </Space>

  );
}

export default EditableLabel;
