export default class CorridorStatusBase {
  idx: number = 0;
  corridor_name: string = "";
  status: string = "";
  reading_percentage: number = 0;

  public static from(json: any) {
    return Object.assign(new CorridorStatusBase(), json) as CorridorStatusBase;
  }
}
