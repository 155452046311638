import React, { useState, useEffect, useCallback } from 'react';
import ImageGrid from './ImageGrid';
import { /*Button,*/ Col, Divider, Row, Select, Space, Spin } from 'antd';
import 'antd/dist/reset.css';
import './BigPicture.css';
import { CorridorSummary } from '../../../../../../models/Corridor';
import RackTemplate from '../../../../../../models/RackTemplate';
import RackTemplateService from '../../../../../../services/RackTemplateService';
import NavigationArrows from './NavigationArrows';
import WarehouseShow from '../../../../../../models/Warehouse';
import WarehouseService from '../../../../../../services/WarehouseService';
import Position from '../../../../../../models/Position';


interface BigPictureProps {
  warehouse: WarehouseShow;
  warehouseId: string;
}

const BigPicture: React.FC<BigPictureProps> = ({ warehouse, warehouseId }) => {
  const [corridor, setCorridor] = useState<CorridorSummary | undefined>();
  const [position, setPosition] = useState({ row: 0, col: 0 });
  const [loading, setLoading] = useState<boolean>(true);
  const [rackTemplates, setRackTemplates] = useState<RackTemplate[]>([]);
  const [rackTemplate, setRackTemplate] = useState<RackTemplate | undefined>();
  const [positions,setPositions] = useState<Position[]>();
  const [corridors, setCorridors] = useState<CorridorSummary[]>([]);
  // const [isInverted, setIsInverted] = useState<boolean>(false);
  const windowHeight = window.innerHeight * 0.6;
  const windowWidth = window.innerWidth * 0.8;
  let gridSizeY = rackTemplate ? rackTemplate.level_range[1] - rackTemplate.level_range[0] + 1 : 5;
  let gridSizeX = Math.floor(windowWidth*gridSizeY/(windowHeight*16/9));


  const createNullGrid = (m: number, n: number): (null[][]) => Array.from({ length: m }, () => Array(n).fill(null));
  function parsePosLabel(posLabel: string): { corridor: string; column: number; level: number; } {
    if (posLabel.length === 4) {
      posLabel = " " + posLabel
    }
    const corridor = posLabel.substring(0, 2).trim();
    const column = parseInt(posLabel.substring(2, 4));
    const level = parseInt(posLabel[4]);

    return { corridor, column, level };
  }

  function posLabel(c: number, l: number, corridor: string): string {
    return `${corridor}${c.toString().padStart(2, '0')}${l}`;
  }

  const make_grid = () => {
    if (rackTemplate !== undefined && corridor !== undefined&& positions!== undefined) {
      const m: number = rackTemplate.column_range[1] - rackTemplate.column_range[0];
      const n: number = rackTemplate.level_range[1] - rackTemplate.level_range[0];
      var grid: Array<Array<Position | null>> = createNullGrid(m, n);
      // console.log("GRID LENGHT", grid[0].length, grid.length, m, n, rackTemplate)
      for (let x = 0; x < grid[0].length; x++) {
        for (let y = 0; y < grid.length; y++) {
          var position = new Position()
          position.position_label = posLabel(x + rackTemplate.column_range[0], y + rackTemplate.level_range[0], corridor.name)
          position.pallet_label = ""
          grid[x][y] = position
        }
      }
      positions.forEach((position, index) => {
        const parsed_pos = parsePosLabel(position.position_label);
        const x = parsed_pos.column - rackTemplate.column_range[0];
        const y = parsed_pos.level - rackTemplate.level_range[0];
        if (x < m && y < n && x >= 0 && y >= 0 && corridor.name.trim() === parsed_pos.corridor) {
          grid[x][y] = position;
        }
      });
      if (rackTemplate.orientation === "right") {
        grid = grid.reverse()
      }
      return grid;
    }
    return null;
  }

  const make_subgrid = (grid: Array<Array<Position | null>> | null) => {
    if (grid === null || rackTemplate === undefined) return [[]];
    const grid_w = grid.length;
    const grid_h = grid[0].length;
    const x_min = position.col;
    const y_min = position.row;

    var subgrid: Array<Array<Position | null>> = createNullGrid(gridSizeY, gridSizeX);

    for (let x = 0; x < gridSizeX ; x++) {
      for (let y = 0; y < gridSizeY; y++) {
        if (x + x_min >= 0 && x + x_min < grid_w && y + y_min >= 0 && y + y_min < grid_h) {
          subgrid[gridSizeY - 1 - y][x] = grid[x + x_min][y + y_min];
        }
      }
    }
    // console.log("SUBGRID", subgrid);

    return subgrid;
  }

  const grid = make_grid();
  const subgrid = make_subgrid(grid);

  const updateContent = useCallback(() => {
    if (rackTemplates.length && corridors.length) return;

    setLoading(true);
      setCorridors(warehouse.corridors)
      WarehouseService.getPositions(warehouseId).then((positions)=>{
        if (positions===null){
          return
        }
        setPositions(positions)
        
        RackTemplateService.getAll().then((racktemplates) => {
          if (racktemplates) {
            setRackTemplates(racktemplates);
            setLoading(false);
          }
        });
      })
  }, [rackTemplates.length, corridors.length,warehouseId,warehouse.corridors]);

  const onCorridorChange = (value: string) => {
    const selectedCorridor = corridors.find((corridor) => corridor.name === value);
    setCorridor(selectedCorridor);
    if (selectedCorridor) {
      const selectedRackTemplate = rackTemplates.find(
        (rack) => rack.template_name === selectedCorridor.rack_template
      );
      setRackTemplate(selectedRackTemplate);
      if (selectedRackTemplate) {
        setPosition({ row: 0, col: 0 });
      }
    }
  };

  useEffect(() => {
    updateContent();
    const interval = setInterval(updateContent, 1000000);
    return () => clearInterval(interval);
  }, [updateContent]);

  useEffect(() => {
    if (corridor) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [corridor]);

  // const handleSwapOrientation = () => {
  //   setIsInverted(!isInverted);
  //   setPosition({ row: 0, col: 0 });
  // };
  const handleNavigate = (direction: 'left' | 'right' | 'doubleLeft' | 'doubleRight' | 'topLeft' | 'topRight') => {
    if (!rackTemplate) return;

    setPosition((prevPosition) => {
      let { row, col } = prevPosition;
      const maxCol = Math.max(0, rackTemplate.column_range[1] - rackTemplate.column_range[0] - gridSizeX);

      switch (direction) {
        case 'left':
          col = Math.max(col - 1, 0);
          break;
        case 'right':
          col = Math.min(col + 1, maxCol);
          break;
        case 'doubleLeft':
          col = Math.max(col - gridSizeX, 0);
          break;
        case 'doubleRight':
          col = Math.min(col + gridSizeX, maxCol);
          break;
        case 'topLeft':
          col = 0;
          break;
        case 'topRight':
          col = maxCol;
          break;
      }

      return { row, col };
    });
  };

  return (
    <Spin spinning={loading}>
      <Space>
        Corridor
        {corridors && (
          <Select
            style={{ width: 120 }}
            onChange={onCorridorChange}
            options={corridors.map((corridor) => ({
              label: corridor.name,
              value: corridor.name,
            }))}
          />
        )}
      </Space>
      {/* <Space style={{ paddingLeft: "20px" }}>
        <Button onClick={handleSwapOrientation}>
          Swap orientation
        </Button>
      </Space> */}
      <Divider />
      {corridor && grid && rackTemplate && (
        <Row>
          <Col span={2}>
            <NavigationArrows onNavigate={handleNavigate} corridorName={corridor.name}
              position={position}
              racktemplate={rackTemplate}
              hideArrows={{ right: true }}
            />
          </Col>
          <Col span={20}>
            <ImageGrid
              subgrid={subgrid}
              gridSizeX={gridSizeX}
              gridSizeY={gridSizeY}
              warehouseId={parseInt(warehouseId)}
              windowHeight={windowHeight}
              windowWidth={windowWidth}
            />
          </Col>
          <Col span={2}>
            <NavigationArrows onNavigate={handleNavigate} corridorName={corridor.name}
              position={position}
              racktemplate={rackTemplate}
              hideArrows={{ left: true }} />
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default BigPicture;