import React, { useEffect, useRef, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Skeleton,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Radio,
  Divider,
} from "antd";
import RackTemplate from "../../../../../models/RackTemplate";
import RackTemplateMap from "./RackTemplateMap";
import RackTemplateService from "../../../../../services/RackTemplateService";
import { useNavigate } from "react-router-dom";

const RackTemplateCreate = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [rackTemplate, setRackTemplate] = useState<RackTemplate>();
  const [clickedButton, setClickedButton] = useState<string | null>(null);
  const [createCardHeight, setCreateCardHeight] = useState<number | undefined>(
    undefined
  );
  const createCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const createCard = createCardRef.current;
      if (createCard) {
        setCreateCardHeight(createCard.getBoundingClientRect().height);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    const createCard = createCardRef.current;
    if (createCard) {
      resizeObserver.observe(createCard);
    }
    return () => {
      if (createCard) {
        resizeObserver.unobserve(createCard);
      }
    };
  }, []);

  const onFinish = (values: any) => {
    const { template_name, level_range, column_range, orientation, ignore_cells } = values;
    const rackTemplate = new RackTemplate();
    rackTemplate.template_name = template_name;
    rackTemplate.level_range = level_range;
    rackTemplate.column_range = column_range;
    rackTemplate.orientation = orientation;
  
    rackTemplate.ignore_cells = ignore_cells
      ? ignore_cells.map((cell: [number, string]) => {
          const [column, rows] = cell;
          const rowArray = rows.split(",").map((row) => parseInt(row.trim(), 10));
          return [column, rowArray];
        })
      : [];
  
    if (clickedButton === "loadPreviewButton") {
      setRackTemplate(rackTemplate);
      setLoading(false);
    } else if (clickedButton === "submitButton") {
      RackTemplateService.createOne(rackTemplate);
      navigate("/racktemplates");
    }
  };

  return (
    <Card title="Create Rack Template">
      <Row gutter={[16, 16]} justify="center" align="top">
        <Col style={{ marginRight: 16 }}>
          <Card
            title="Define Parameters"
            ref={createCardRef}
            style={{ maxWidth: 600 }}
          >
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              style={{
                maxWidth: 600,
              }}
            >
              <Form.Item
                label="Template Name"
                name="template_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter a template name",
                  },
                ]}
              >
                <Input placeholder="Template Name" />
              </Form.Item>

              <Form.Item
                label="Level Range"
                name="level_range"
                rules={[
                  { required: true, message: "Please enter level range" },
                ]}
              >
                <Input.Group compact>
                  <Form.Item
                    name={["level_range", 0]}
                    noStyle
                    rules={[
                      { required: true, message: "Please enter min level" },
                    ]}
                  >
                    <InputNumber placeholder="Min Level" min={1} max={12}/>
                  </Form.Item>
                  <Form.Item
                    name={["level_range", 1]}
                    noStyle
                    rules={[
                      { required: true, message: "Please enter max level" },
                    ]}
                  >
                    <InputNumber placeholder="Max Level" min={1} max={12}/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>

              <Form.Item
                label="Column Range"
                name="column_range"
                rules={[
                  { required: true, message: "Please enter column range" },
                ]}
              >
                <Input.Group compact>
                  <Form.Item
                    name={["column_range", 0]}
                    noStyle
                    rules={[
                      { required: true, message: "Please enter min column" },
                    ]}
                  >
                    <InputNumber placeholder="Min Column" min={1} max={60}/>
                  </Form.Item>
                  <Form.Item
                    name={["column_range", 1]}
                    noStyle
                    rules={[
                      { required: true, message: "Please enter max column" },
                    ]}
                  >
                    <InputNumber placeholder="Max Column" min={1} max={60}/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                name={"orientation"}
                label="Orientation"
                rules={[
                  { required: true, message: "Please select orientation" },
                ]}
            >
                <Radio.Group>
                    <Radio value={"right"}>Right</Radio>
                    <Radio value={"left"}>Left</Radio>
                </Radio.Group>
            </Form.Item>
            <Divider/>
              <Form.List name="ignore_cells">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 0]}
                          rules={[
                            {
                              required: true,
                              message: "Missing column value",
                            },
                          ]}
                        >
                          <InputNumber placeholder="Column" min={1} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 1]}
                          rules={[
                            {
                              required: true,
                              message: "Missing row values",
                            },
                          ]}
                        >
                          <Input placeholder="Rows (comma-separated)" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add cell to ignore
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Row>
                <Col span={12}>
                  <Form.Item style={{ textAlign: "center", marginBottom: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => setClickedButton("loadPreviewButton")}
                    >
                      Load Preview
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item style={{ textAlign: "center", marginBottom: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => setClickedButton("submitButton")}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col>
          <Card
            title="Preview"
            loading={false}
            style={{ width: 1200, height: createCardHeight }}
          >
            <Skeleton
              active
              loading={loading}
              paragraph={{
                rows: createCardHeight
                  ? Math.floor(createCardHeight / 35) - 3
                  : 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {rackTemplate ? (
                  <RackTemplateMap
                    rackTemplate={rackTemplate}
                    containerHeight={createCardHeight}
                  />
                ) : null}
              </div>
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default RackTemplateCreate;
