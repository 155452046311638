import { Table } from "antd";
import { SweepPositionShow } from "../../../../../../models/Sweep";
import { useState } from "react";
import EditableLabel from "../../subcomponents/EditLabel";

interface SummaryTableProps {
  sweepId: number
  positions: SweepPositionShow[];
  onSort?: (column_key: string, order: string) => void;
  onUpdateContent: () => void;
  repeatedPositions: string[];
  sweepStatus: string
}

const SummaryTable = (props: SummaryTableProps) => {
  const [pageSize, setPageSize] = useState<number>(10);

  const columns = [
    {
      title: "Position Label",
      key: "position_label",
      render: (item: SweepPositionShow) => {
        const repeated = props.repeatedPositions.includes(item.position_label)
        return (
          <EditableLabel
            sweepId={props.sweepId ? props.sweepId.toString() : "-1"}
            onUpdateContent={props.onUpdateContent}
            label={item.position_label}
            prev_position_label={item.position_label}
            labelType={"position"}
            editable={false}
            isEmpty={item.state === "empty" ? true : false}
            repeated={repeated}
          />
        );
      },
    },

    {
      title: "Pallet Label",
      key: "pallet_label",
      render: (item: SweepPositionShow) => {
        if (item.state === "empty") {
          return (
            <EditableLabel
              editable={props.sweepStatus === "processing" ? true : false}
              sweepId={props.sweepId ? props.sweepId.toString() : "-1"}
              onUpdateContent={props.onUpdateContent}
              label={"Empty"}
              prev_position_label={item.position_label}
              labelType={"pallet"}
              isEmpty={true}
              repeated={false}
            />
          );
        }
        return (<EditableLabel
          editable={props.sweepStatus === "processing" ? true : false}
          onUpdateContent={props.onUpdateContent}
          label={(item.pallet_label !== undefined && item.pallet_label !== null) ? item.pallet_label : ""}
          prev_position_label={item.position_label}
          labelType={"pallet"}
          isEmpty={false}
          repeated={false}
        />
        );
      },
    },
  ];
  const paginationConfig = {
    pageSize: pageSize,
    total: props.positions.length,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"],
    onChange: (page: number, pageSize: number | undefined) => {
      if (pageSize) {
        setPageSize(pageSize);
      }
    }
  }


  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    if (props.onSort) {
      props.onSort(sorter.columnKey, sorter.order);
    }
  };

  return (
    <Table
      columns={columns}
      dataSource={props.positions}
      rowKey="id"
      scroll={{ x: true }}
      size="small"
      pagination={paginationConfig}
      showSorterTooltip={true}
      onChange={handleTableChange}
    />
  );
};

export default SummaryTable;
