import axios, { AxiosResponse } from "axios";
import UserService from "./UserService";
import WarehouseShow, { PendingCount, WarehouseSummary } from "../models/Warehouse";
import Position from "../models/Position";
import Warehouse from "../models/Warehouse";
import { NavigateFunction } from "react-router-dom";
import { notification } from "antd";

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "warehouses/";

export default class WarehouseService {
  public static async getAll(navigate:NavigateFunction): Promise<WarehouseSummary[] | null> {
    try {
      const response: AxiosResponse<WarehouseSummary[]> = await axios.get(
        API_BASE_URL + API_RESOURCE,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const warehouses = response.data.map((warehouse) =>
        WarehouseSummary.from(warehouse)
    );
    return warehouses;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      notification.error({
        message: "Insufficient Credentials",
        description:"Update your credentials to access here"
      });
      if (error.response.status === 401) {
        sessionStorage.removeItem("token")
        navigate("/login")
        return null;
      }
    }
    return null;
  }
}
  
  public static async getOne(id: string,navigate: NavigateFunction): Promise<WarehouseShow | null> {
    try {
      const response: AxiosResponse<WarehouseShow> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + '/',
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const warehouse = WarehouseShow.from(response.data);
      return warehouse;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }
  
  public static async getPositions(id: string): Promise<Position[] | null> {
    try {
      const response: AxiosResponse<Position[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + "/positions",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const positions = response.data.map((warehouse) =>
        Position.from(warehouse)
    );
    return positions;
  } catch (error) {
    console.error(error);
      return null;
    }
  }


  public static async getPositionsPaginated(id: string, skip: number = 10,limit:number = 10, request: {position:string,pallet:string,corridor:string,column:string,level:string}): Promise<Position[] | null> {
    try {
      const response: AxiosResponse<Position[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + `/positions-paginated?skip=${skip}&limit=${limit}&position=${request.position}&pallet=${request.pallet}&corridor=${request.corridor}&column=${request.column}&level=${request.level}`,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const positions = response.data.map((warehouse) =>
        Position.from(warehouse)
      );
      return positions;
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  public static async getPositionsPaginatedCount(id: string, request: {position:string,pallet:string,corridor:string,column:string,level:string}): Promise<number | null> {
    try {
      const response: AxiosResponse<number> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + `/positions-paginated/count?&position=${request.position}&pallet=${request.pallet}&corridor=${request.corridor}&column=${request.column}&level=${request.level}`,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const positions_count = response.data
      return positions_count;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getPositionsByCorridor(
    id: string,
    corridor: string
  ): Promise<Position[] | null> {
    try {
      const response: AxiosResponse<Position[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + "/" + corridor + "/positions",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const positions = response.data.map((warehouse) =>
        Position.from(warehouse)
      );
      return positions;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async createOne(
    warehouse: Warehouse
  ): Promise<Warehouse | null> {
    try {
      const response: AxiosResponse<Warehouse> = await axios.post(
        API_BASE_URL + API_RESOURCE,
        warehouse,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const createdRackTemplate = Warehouse.from(response.data);
      return createdRackTemplate;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async exportCSV(idx: string): Promise<void> {
    try {
      const response: AxiosResponse = await axios.post(
        API_BASE_URL + API_RESOURCE + idx + "/csv",
        {},
        {
          headers: UserService.getAuthHeader(),
          responseType: 'blob'
        }
      );
      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv' });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'positions.csv');
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading positions CSV:', error);
      // Handle error
    }
  }

  public static async getPendingCount(id: string): Promise<PendingCount | null> {
    try {
      const response: AxiosResponse<PendingCount> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + `/pending-count`,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const positions_count = response.data
      return positions_count;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

}
