export default class Position {
    id: string = "";
    idx: number = 0;
    position_label: string = "";
    pallet_label: string = "";
    last_update: string = "";
    state: string = "unknown";
  
    public static from(json: any) {
      return Object.assign(new Position(), json) as Position;
    }
  }
