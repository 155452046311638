import { Input, Col, Row, Space } from "antd";
import { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { debounce } from 'lodash';

interface PositionsControlsProps {
  onApply: (
    position: string,
    pallet: string,
    corridor: string,
    column: string,
    level: string
  ) => void;
}

const PositionsControls = (props: PositionsControlsProps) => {
  const [filters, setFilters] = useState({
    position: "",
    pallet: "",
    corridor: "",
    column: "",
    level: "",
  });

  const filtersRef = useRef(filters);

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  const debouncedApply = useMemo(
    () =>
      debounce(() => {
        const { position, pallet, corridor, column, level } = filtersRef.current;
        props.onApply(position, pallet, corridor, column, level);
      }, 1500),
    [props]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const newFilters = { ...filters, [key]: e.target.value };
    setFilters(newFilters);
    debouncedApply(); // Call the debounced function on input change
  };

  const onApply = useCallback(() => {
    const { position, pallet, corridor, column, level } = filters;
    props.onApply(position, pallet, corridor, column, level);
  }, [filters, props]);

  return (
    <Row gutter={[10, 10]} align="middle">
      <Col xs={24} sm={12} md={8} lg={4}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Position
          <Input
            placeholder="Position"
            value={filters.position}
            onChange={(e) => handleInputChange(e, 'position')}
            onPressEnter={onApply}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={8} lg={4}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Pallet
          <Input
            placeholder="Pallet"
            value={filters.pallet}
            onChange={(e) => handleInputChange(e, 'pallet')}
            onPressEnter={onApply}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={8} lg={4}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Corridor
          <Input
            placeholder="Corridor"
            value={filters.corridor}
            onChange={(e) => handleInputChange(e, 'corridor')}
            onPressEnter={onApply}
          />
        </Space>
      </Col>
      <Col xs={12} sm={6} md={4} lg={2}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Column
          <Input
            placeholder="Column"
            value={filters.column}
            onChange={(e) => handleInputChange(e, 'column')}
            onPressEnter={onApply}
          />
        </Space>
      </Col>
      <Col xs={12} sm={6} md={4} lg={2}>
        <Space direction="vertical" style={{ width: '100%' }}>
          Level
          <Input
            placeholder="Level"
            value={filters.level}
            onChange={(e) => handleInputChange(e, 'level')}
            onPressEnter={onApply}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default PositionsControls;
