import React, { useContext, useState, useEffect } from "react";
import { Col, Layout, Row, Switch } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import LeftMenu from "./LeftMenu";
import Flag from "react-world-flags";
import UserContext from "../../context/UserContext"; // Import the context
import { useLanguage } from "../../context/LanguageContext";

const { Header, Sider, Content } = Layout;

interface AppLayoutProps {
  children?: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const {language, setLanguage } = useLanguage();
  const { isMobile } = useContext(UserContext); // Use context
  const [collapsed, setCollapsed] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(language === "en");

  useEffect(() => {
    setSwitchChecked(language === "en");
  }, [language]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleBreakpoint = (broken: boolean) => {
    setCollapsed(broken);
  };

  const handleSwitchChange = (checked: boolean) => {
    setSwitchChecked(checked);
    setLanguage(checked ? "en" : "es"); // Change language based on switch
  };

  return (
    <Layout style={{ minHeight: "100vh", overflow: "hidden" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth="0"
        breakpoint="lg"
        onBreakpoint={handleBreakpoint}
        style={{
          position: isMobile ? "fixed" : "fixed",
          zIndex: isMobile ? 10 : 1,
          height: "100vh",
          width: isMobile ? (collapsed ? "0" : "200px") : "200px",
          left: isMobile && collapsed ? "-200px" : "0",
          transition: "all 0.3s ease",
          overflow: "auto",
        }}
      >
        <div className="logo">
          <img
            src="/logo.png"
            style={{
              maxHeight: "90%",
              maxWidth: "90%",
              borderRadius: "5px",
              background: "#FFFFFF",
              padding: "5px 15px",
            }}
            alt="Logo"
          />
        </div>
        <LeftMenu setCollapsed={toggle} />
      </Sider>

      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 0 : isMobile ? 0 : 200,
          transition: "margin-left 0.3s ease",
        }}
      >
        <Header
          style={{
            background: "#114373",
            padding: 0,
            position: "fixed",
            width: "100%",
            zIndex: 2,
          }}
          className="site-layout-background"
        >
          <Row justify="space-between" align="middle">
            <Col>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: toggle,
                  style: {
                    color: "#fff",
                    padding: "0 24px",
                    marginLeft: isMobile && !collapsed ? 190 : 0,
                    cursor: "pointer",
                    fontSize: "18px",
                  },
                }
              )}
            </Col>
            <Col>
              <div
                style={{
                  position: "fixed",
                  top: "16px",
                  right: "24px",
                  zIndex: 11,
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Flag
                  code={switchChecked ? "GB" : "AR"} // Toggle between US and AR flags
                  style={{
                    width: "24px",
                    height: "16px",
                    borderRadius: "2px",
                  }}
                />
                <Switch
                  checked={switchChecked}
                  onChange={handleSwitchChange} // Handle switch change
                />
              </div>
            </Col>
          </Row>
        </Header>

        <Content
          className="content"
          style={{
            padding: "64px 12px 12px",
            minHeight: "100vh",
            overflow: "auto",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
