import axios, { AxiosResponse } from "axios";
import UserService from "./UserService";
import RackTemplate from "../models/RackTemplate";

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "racktemplates/";

export default class RackTemplateService {
  public static async getAll(): Promise<RackTemplate[] | null> {
    try {
      const response: AxiosResponse<RackTemplate[]> = await axios.get(
        API_BASE_URL + API_RESOURCE,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const racktemplates = response.data.map((racktemplate) =>
      RackTemplate.from(racktemplate)
      );
      return racktemplates;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async getOne(id: string): Promise<RackTemplate | null> {
    try {
      const response: AxiosResponse<RackTemplate> = await axios.get(
        API_BASE_URL + API_RESOURCE + id,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const racktemplate = RackTemplate.from(response.data);
      return racktemplate;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async createOne(rackTemplate: RackTemplate): Promise<RackTemplate | null> {
    try {
      const response: AxiosResponse<RackTemplate> = await axios.post(
        API_BASE_URL + API_RESOURCE,
        rackTemplate,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const createdRackTemplate = RackTemplate.from(response.data);
      return createdRackTemplate;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
