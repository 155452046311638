import { Table } from "antd";
import Position from "../../../../../../models/Position";
import moment from 'moment';
import React from "react";
import { WarehousePictureService } from "../../../../../../services/PictureService";
import ImageDisplayer from "../../../../../utils/ImageDisplayer";
import { WarehouseThumbnailService } from "../../../../../../services/ThumbnailService";

interface PositionsTableProps {
  positionsCount: number;
  page: number;
  pageSize: number;
  onPageChange: (page:number,pageSize:number) => void;
  positions: Position[];
  loading: boolean;
  warehouseId: number;
  isMobile: boolean;
  showPictures:boolean;
}

const PositionsTable = (props: PositionsTableProps) => {
  let columns = [
    {
      title: "Position Label",
      key: "position_label",
      render: (item: Position) => item.position_label,
    },
    {
      title: "Position Image",
      key: "image",
      render: (item: Position) => {
        return (
          <ImageDisplayer
            fetchFunction={() => {
              const paddedPositionLabel = item.position_label.padStart(5, ' ');
              return WarehousePictureService.getWarehousePositionPicture(props.warehouseId, paddedPositionLabel);
            }}
            lowfetchFuntion={(abortSignal: AbortSignal) => {
              const paddedPositionLabel = item.position_label.padStart(5, ' ');
              return WarehouseThumbnailService.getWarehousePositionThumbnail(props.warehouseId, paddedPositionLabel, abortSignal);
            }}
            height={"50px"}
            width={"88.88px"}
          />
        );
      },
    },
    {
      title: "Pallet Label",
      key: "pallet_label",
      render: (item: Position) => item.pallet_label,
    },
    {
      title: "Pallet Image",
      key: "image_pallet",
      render: (item: Position) => {
        return (
          <ImageDisplayer
            fetchFunction={() => {
              const paddedPositionLabel = item.position_label.padStart(5, ' ');
              return WarehousePictureService.getWarehousePalletPicture(props.warehouseId, paddedPositionLabel);
            }}
            lowfetchFuntion={(abortSignal: AbortSignal) => {
              const paddedPositionLabel = item.position_label.padStart(5, ' ');
              return WarehouseThumbnailService.getWarehousePalletThumbnail(props.warehouseId, paddedPositionLabel, abortSignal);
            }}
            height={"50px"}
            width={"88.88px"}
          />
        );
      },
    },
    {
      title: "Last Update",
      key: "last_update",
      render: (item: Position) =>
        item.last_update ? moment(item.last_update).format("YYYY-MM-DD HH:mm:ss") : "",
    },
    {
      title: "State",
      key: "state",
      render: (item: Position) => item.state,
    },
  ];

  // Filter out the image columns when showPictures is false
  if (!props.showPictures) {
    columns = columns.filter(
      (column) => column.key !== "image" 
    );
  }

  const paginationConfig = {
    pageSize: props.pageSize,
    total: props.positionsCount,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"],
    onChange: (page: number, pageSize: number | undefined) => {
      if (pageSize) {
        props.onPageChange(page, pageSize);
      }
    },
  };

  return (
    <Table
      columns={columns}
      dataSource={props.positions}
      loading={props.loading}
      rowKey="id"
      scroll={{ x: (props.isMobile ? 800: 1000) }} // Ensures horizontal scrolling
      size="small"
      pagination={paginationConfig}
      showSorterTooltip={true}
    />
  );
};


export default PositionsTable;
