import { Input, Col, Row, Space, Radio, Slider, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import { useLanguage } from "../../../../../../context/LanguageContext";

interface PositionsControlsProps {
  onApply: (
    status: string[],
    corridor: string,
    reading: number[]
  ) => void;

}
const CorridorsStatusControls = (props: PositionsControlsProps) => {
  const { language } = useLanguage();
  const [readingFilter, setReadingFilter] = useState<number[]>([0,100]);
  const [corridorName, setCorridorName] = useState<string>("");
  const [status, setStatus] = useState<string[]>([]);
  
  const handleSliderChange = (value: number[]) => {
    setReadingFilter(value);
    console.log('Filter range:', value);
};
  const onApply = useCallback(() => {
    props.onApply(status, corridorName, readingFilter);
  }, [props, readingFilter, corridorName, status]);

  useEffect(() => {
    onApply();
  }, [onApply]);



  return (
    <Row gutter={[20, 5]} align="top" style={{ padding: "10px 0" }}>
      <Col xs={24} sm={8} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Pasillo:" : "Corridor:"}</span>
          <Input
            allowClear
            style={{width: "80%"}}
            placeholder={language === "es" ? "Pasillo" : "Corridor"}
            value={corridorName}
            onChange={(e) => setCorridorName(e.target.value)}
          />
        </Space>
      </Col>
      <Col xs={24} sm={8} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Mostrar:" : "Show:"}</span>
          <Select
            style={{ width: "80%" }}
            value={status}
            mode="multiple"
            placeholder={language === "es" ? "Todos" : "All"}
            onChange={setStatus}
            options={[
              { value: "in_progress", label: language === "es" ? "En curso" : "In Progress" },
              { value: "unexplored", label: language === "es" ? "Sin explorar" : "Unexplored" },
              { value: "done", label: language === "es" ? "Terminado" : "Done" },
              { value: "incomplete", label: language === "es" ? "Incompleto" : "Incomplete" },
            ]}
          />
        </Space>
      </Col>
      <Col xs={24} sm={8} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Lectura:" : "Reading:"}</span>
            <Slider 
                marks={{0:   language === "es" ? "Mín":"Min",
                        80:   "80%",
                        50:   "50%",
                        100: language === "es" ? "Máx":"Max",}}
                range
                style={{width: "60%"}} 
                min={0} 
                max={100} 
                value={readingFilter} 
                onChange={handleSliderChange}
            />
        </Space>
      </Col>
    </Row>
  );
};

export default CorridorsStatusControls;
