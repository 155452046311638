import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Card, Col, notification, Row, Spin, Tabs, TabsProps, Tag, Tooltip } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import WarehouseShow, { PendingCount } from "../../../../../models/Warehouse";
import WarehouseService from "../../../../../services/WarehouseService";
import CorridorsTable from "./tab1/CorridorsTable";
import Positions from "./tab2/Positions";
import WarehouseMap from "./tab3/WarehouseMap";
import BigPicture from "./tab4/BigPicture";
import { CheckCircleOutlined, FormOutlined, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import SettingService from "../../../../../services/SettingsService";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import UserContext from "../../../../../context/UserContext";
import { useLanguage } from "../../../../../context/LanguageContext";

const WarehouseDetails = () => {
  const isMobile = useContext(UserContext).isMobile;
  const { language } = useLanguage();
  const { warehouseId } = useParams();
  const screens = useBreakpoint();
  const [warehouse, setWarehouse] = useState(new WarehouseShow());
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pendingCount, setPendingCount] = useState<PendingCount>();
  const [showPictures, setShowPictures] = useState<boolean>(false);
  const [layoutStatus, setLayoutStatus] = useState<string>("");
  let tabId = searchParams.get("tab") || "1";
  const navigate = useNavigate();

  const updateLayoutStatus = useCallback(() => {
    if (warehouseId) {
      WarehouseService.getWarehouseLayoutStatus(parseInt(warehouseId)).then((response) => {
        if (response) {
          setLayoutStatus(response)
        } else {
          notification.error({
            message: language === "es" ? "Se produjo un error al intentar ver el estado del layout" : "There was an error trying to view the layout status",
          })
        }
      })
    }
  }, [warehouseId]);

  useEffect(() => {
    if (warehouseId) {
      updateLayoutStatus();
    }
    const intervalId = setInterval(() => {
      if (warehouseId) {
        updateLayoutStatus();
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [updateLayoutStatus, warehouseId]);

  const updateContent = useCallback(() => {
    if (warehouseId) {
      WarehouseService.getOne(warehouseId, navigate).then((warehouse) => {
        if (warehouse == null) {
          return;
        }
        setWarehouse(warehouse);
        setLoading(false);
      });
      SettingService.getSettings().then((setting) => {
        if (setting == null) {
          return;
        }
        setShowPictures(setting.show_warehouse_positions_pictures)
      })
    }
  }, [warehouseId, navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const updatePending = useCallback(() => {
    if (warehouseId) {
      WarehouseService.getPendingCount(warehouseId).then((count) => {
        if (count == null) {
          return;
        }
        setPendingCount(count);
      });
    }
  }, [warehouseId]);

  useEffect(() => {
    updatePending();
    const interval = setInterval(() => {
      updatePending();
    }, 60000);
    return () => clearInterval(interval);
  }, [updatePending]);

  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label: language === "es" ? "Racks" : "Corridors",
      children: <CorridorsTable corridors={warehouse.corridors} />,
    },
    {
      key: "2",
      label: language === "es" ? "Posiciones" : "Positions",
      children: (
        <>
          {warehouseId === undefined ? (
            <></>
          ) : (
            <Positions warehouseId={warehouseId} isMobile={isMobile} showPictures={showPictures} />
          )}
        </>
      ),
    },
    ...(!screens.lg
      ? []
      : [
        {
          key: "3",
          label: language === "es" ? "Mapa" : "Map",
          children: (
            <>
              {warehouseId === undefined ? (
                <></>
              ) : (
                <WarehouseMap warehouseId={warehouseId} isMobile={isMobile} />
              )}
            </>
          ),
        },
        {
          key: "4",
          label: language === "es" ? "Gigantografia" : "Big Picture",
          children: (
            <>
              {warehouseId === undefined ? (
                <></>
              ) : (
                <BigPicture warehouseId={warehouseId} warehouse={warehouse} />
              )}
            </>
          ),
        },]),
  ];
  return (
    <>
      <Spin spinning={loading}>
        <Card
          title={<Row gutter={[10, 1]}><Col>
            <div style={{fontSize: "16px"}}>
            {(!screens.lg ? true : false) ? warehouse.name + " - " + warehouse.warehouse_number : language === "es" ? "Depósito: " + warehouse.name + " - " + warehouse.warehouse_number : "Warehouse: " + warehouse.name + " - " + warehouse.warehouse_number}
            </div>
          </Col>
            {isMobile === false && <Col>
              <Tooltip placement="top" title={language === "es" ? (layoutStatus === "Sweep Running" ? "Barrido en curso" : layoutStatus === "Layout Updating" ? "Actualizando Layout" : "Disponible para actualizar" ):layoutStatus}>
                <Link to={`/warehouses/${warehouseId}/layout`} >
                  <Button
                    type="primary"
                    size="small"
                    shape="round"
                    disabled={layoutStatus === "Sweep Running" || layoutStatus === "Layout Updating"}
                    icon={layoutStatus === "Layout Updating" ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : <FormOutlined />}
                  >
                    {language === "es" ? "Layout" : "Layout"}
                  </Button>
                </Link>
              </Tooltip>
            </Col>
            }
          </Row>
          }
          extra={
            pendingCount && <Row><Col> <Tag color={pendingCount.pending_count > 0 ? "blue" : "green"} icon={pendingCount.pending_count > 0 ? <SyncOutlined spin /> : <CheckCircleOutlined />}>
              {(!screens.lg ? true : false) ? pendingCount.pending_count > 0 ?
                language === "es" ? "Pendiente a JD" + pendingCount.pending_count : "Pending to JD: " + pendingCount.pending_count
                : language === "es" ? "JD al día" : "JD up to date"
                : pendingCount.pending_count > 0 ? language === "es" ? "Lecturas pendientes a JD: " + pendingCount.pending_count : "Pending readings to JD: " + pendingCount.pending_count
                  : language === "es" ? "Lecturas a JD al día" : "Readings to JD up to date"}
            </Tag>
              {(screens.lg ? true : false) && <div style={{ fontSize: window.innerWidth < 900 ? "3vw" : "80%", color: "#7a848d" }}>
                {language === "es" ? "última actualización: " + new Date(new Date(pendingCount.last_update).getTime() - 3 * 60 * 60 * 1000).toLocaleString()
                  : "last update: " + new Date(new Date(pendingCount.last_update).getTime() - 3 * 60 * 60 * 1000).toLocaleString()}
              </div>}</Col>
            </Row>
          }
          style={{
            margin: isMobile ? '10px' : '20px',
            padding: isMobile ? '10px' : '20px',
          }}
        >
          <Tabs
            destroyInactiveTabPane={true}
            items={tabs}
            activeKey={tabId}
            onTabClick={(activeKey) => {
              setSearchParams({ tab: activeKey });
            }}
          />
        </Card >
      </Spin >
    </>
  );
};

export default WarehouseDetails;
