import axios from "axios";
import UserService from "./UserService";

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "thumbnails/";

export default class SweepThumbnailService {
  public static async getSweepPositionThumbnail(
    sweep_idx: number,
    position_label: string,
    signal?: AbortSignal
  ): Promise<Blob | null> {
    try {
      return await axios
        .get(
          API_BASE_URL +
            API_RESOURCE +
            "sweeps/positions/picture/" +
            sweep_idx +
            "/" +
            position_label,
          {
            headers: {
              ...UserService.getAuthHeader(),
              "Cache-Control": "no-cache",
            },
            responseType: "blob",
            signal,
          }
        )
        .then((res) => res.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async getSweepPalletThumbnail(sweep_idx: number,position_label: string, signal?: AbortSignal): Promise<Blob | null> {
    try {
      return await axios
        .get(API_BASE_URL + API_RESOURCE + "sweeps/pallets/picture/" + sweep_idx + "/" + position_label,
          {
          headers: {
            ...UserService.getAuthHeader(),
            "Cache-Control": "no-cache",
            
          },
          responseType: "blob",
          signal,
        })
        .then((res) => res.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}

export class WarehouseThumbnailService {
  public static async getWarehousePositionThumbnail(warehouse_idx: number,position_label: string,signal?: AbortSignal): Promise<Blob | null> {
    try {
      return await axios
        .get(API_BASE_URL + API_RESOURCE + "warehouses/positions/picture/" + warehouse_idx + "/" + position_label,
          {
          headers: {
            ...UserService.getAuthHeader(),
            "Cache-Control": "no-cache",
          },
          responseType: "blob",
          signal,
        })
        .then((res) => res.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async getWarehousePalletThumbnail(warehouse_idx: number,postion_label: string, signal?: AbortSignal): Promise<Blob | null> {
    try {
      return await axios
        .get(API_BASE_URL + API_RESOURCE + "warehouses/pallets/picture/" + warehouse_idx + "/" + postion_label,
          {
          headers: {
            ...UserService.getAuthHeader(),
            "Cache-Control": "no-cache",
          },
          responseType: "blob",
          signal,
        })
        .then((res) => res.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
