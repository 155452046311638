import axios, { AxiosResponse } from "axios";
import UserService from "./UserService";
import PalletDevice, {
  DeviceCameraLogs,
  PalletDeviceConnections,
  PalletDeviceCreate,
  PalletDeviceDetails,
  PalletDeviceModify,
} from "../models/PalletDevice";

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "devices/";

export default class PalletDeviceService {
  public static async getAll(): Promise<PalletDevice[] | null> {
    try {
      const response: AxiosResponse<PalletDevice[]> = await axios.get(
        API_BASE_URL + API_RESOURCE,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const palletdevices = response.data.map((palletdevice) =>
        PalletDevice.from(palletdevice)
      );
      return palletdevices;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getOne(id: string): Promise<PalletDeviceDetails | null> {
    try {
      const response: AxiosResponse<PalletDeviceDetails> = await axios.get(
        API_BASE_URL + API_RESOURCE + id,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const palletdevice = PalletDeviceDetails.from(response.data);
      return palletdevice;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async createOne(
    device: PalletDeviceCreate
  ): Promise<PalletDevice | null> {
    try {
      const response: AxiosResponse<PalletDevice> = await axios.post(
        API_BASE_URL + API_RESOURCE,
        device,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async modifyOne(
    device: PalletDeviceModify
  ): Promise<string | null> {
    try {
      const response: AxiosResponse<string> = await axios.put(
        API_BASE_URL + API_RESOURCE + device.idx + "/modify",
        device,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async getConnections(): Promise<
    PalletDeviceConnections[] | null
  > {
    try {
      const response: AxiosResponse<PalletDeviceConnections[]> =
        await axios.get(API_BASE_URL + API_RESOURCE + "connections-status", {
          headers: UserService.getAuthHeader(),
        });
      const palletdevices = response.data.map((palletdevice) =>
        PalletDeviceConnections.from(palletdevice)
      );
      return palletdevices;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async getCurrentReadings(idx:number): Promise<
  DeviceCameraLogs| null
  > {
    try {
      const response: AxiosResponse<DeviceCameraLogs> =
        await axios.get(API_BASE_URL + API_RESOURCE +idx+ "/current_readings", {
          headers: UserService.getAuthHeader(),
        });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

}
