// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
.layout {
  min-height: 100vh;
}

.site-layout-content {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
}

.image-grid {
  display: grid;
  justify-content: center;
}


.grid-image {
  width: 100%;
  height: 100%;
}
.styled-grid-image {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the entire area */
}`, "",{"version":3,"sources":["webpack://./src/components/layout/contents/warehouses/subcomponents/tab4/BigPicture.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAE,wBAAwB;EAC7C,uBAAuB,EAAE,sBAAsB;AACjD;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;;AAGA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB,EAAE,6CAA6C;AAClE","sourcesContent":["/* src/App.css */\n.layout {\n  min-height: 100vh;\n}\n\n.site-layout-content {\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  align-items: center; /* Center horizontally */\n  justify-content: center; /* Center vertically */\n}\n\n.image-grid {\n  display: grid;\n  justify-content: center;\n}\n\n\n.grid-image {\n  width: 100%;\n  height: 100%;\n}\n.styled-grid-image {\n  width: 100%;\n  height: auto;\n  object-fit: cover; /* Ensures the image covers the entire area */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
