import axios, { AxiosResponse } from "axios";
import UserService from "./UserService";
import SweepBase, {
  ReadingUpdateModel,
  SweepCreate,
  SweepInfo,
  SweepPositionShow,
  SweepShow,
} from "../models/Sweep";
import { NavigateFunction } from "react-router-dom";
import { notification } from "antd";

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "sweeps/";

export default class SweepService {
  public static async getInfo(id: string,navigate: NavigateFunction): Promise<SweepInfo | null> {
    try {
      const response: AxiosResponse<SweepInfo> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + "/info",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const sweep = SweepInfo.from(response.data);
      return sweep;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }
  public static async getOne(id: string,navigate: NavigateFunction): Promise<SweepShow | null> {
    try {
      const response: AxiosResponse<SweepShow> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + "/",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const sweep = SweepShow.from(response.data);
      return sweep;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }
  public static async getAll(navigate: NavigateFunction): Promise<SweepBase[] | null> {
    try {
      const response: AxiosResponse<SweepBase[]> = await axios.get(
        API_BASE_URL + API_RESOURCE,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const sweeps = response.data.map((sweeps) => SweepBase.from(sweeps));
      return sweeps;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }
  public static async create(
    sweep_create: SweepCreate
  ): Promise<string | null> {
    try {
      const response: AxiosResponse<string> = await axios.post(
        API_BASE_URL + API_RESOURCE + "create",
        sweep_create,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async updateStatus(
    sweep_base: SweepBase
  ): Promise<SweepBase | null> {
    try {
      const response: AxiosResponse<SweepBase> = await axios.put(
        API_BASE_URL + API_RESOURCE + "status",
        sweep_base,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async updateReading(
    reading: ReadingUpdateModel
  ): Promise<string | null> {
    try {
      const response: AxiosResponse<string> = await axios.put(
        API_BASE_URL + API_RESOURCE + "update",
        reading,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async exportCSV(idx: string): Promise<void> {
    try {
      const response: AxiosResponse = await axios.post(
        API_BASE_URL + API_RESOURCE + idx + "/csv",
        { display_unknown: false },
        {
          headers: UserService.getAuthHeader(),
          responseType: "blob",
        }
      );
      // Create a blob from the response data
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "positions.csv");
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading positions CSV:", error);
      // Handle error
    }
  }
  public static async statusCSV(
    idx: string
  ): Promise<Boolean > {
    try {
      const response: AxiosResponse<Boolean> = await axios.get(
        API_BASE_URL + API_RESOURCE + idx + "/csv-status",
        {
          headers: UserService.getAuthHeader(),
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  
  public static async getSweepPositionsByCorridor(
    id: string,
    corridor: string
  ): Promise<SweepPositionShow[] | null> {
    try {
      const response: AxiosResponse<SweepPositionShow[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + "/" + corridor + "/sweep_positions",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const positions = response.data;
      return positions;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
