import { Input, Col, Row, Space, Checkbox, Select, Button, Radio, Tooltip } from "antd";
import { useState, useCallback, useEffect } from "react";
import { useLanguage } from "../../../../../context/LanguageContext";
import { InfoCircleOutlined } from "@ant-design/icons";

interface PositionsControlsProps {
  onApply: (
    deviceName: string[],
    corridorName: string,
    toShow: number
  ) => void;
  devicesNames: string[];
  handleExpandAll: () => void;
  handleCollapseAll: () => void;

}

const CorridorsControls = (props: PositionsControlsProps) => {
  const { language } = useLanguage();
  const [devicesNames, setDevicesNames] = useState<string[]>([]);
  const [deviceName, setDeviceName] = useState<string[]>([]);
  const [corridorName, setCorridorName] = useState<string>("");
  const [toShow, setToShow] = useState<number>(1);

  const onApply = useCallback(() => {
    props.onApply(deviceName, corridorName, toShow);
  }, [props, deviceName, corridorName, toShow]);

  useEffect(() => {
    onApply();
  }, [onApply]);

  useEffect(() => {
    const updateContent = () => setDevicesNames(props.devicesNames);
    updateContent();
    const interval = setInterval(updateContent, 600000);
    return () => clearInterval(interval);
  }, [props.devicesNames]);

  const onDeviceChange = (values: string[]) => {
    setDeviceName(values);
  };

  return (
    <Row gutter={[20, 20]} align="top" style={{ padding: "10px 0" }}>
      <Col xs={24} sm={8} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Dispositivos:" : "Devices:"}</span>
          <Select
            mode="multiple"
            placeholder={language === "es" ? "Dispositivos" : "Devices"}
            style={{ width: "100%" }}
            onChange={onDeviceChange}
            showSearch={false}
            options={devicesNames.map((deviceName) => ({
              label: deviceName,
              value: deviceName,
            }))}
          />
        </Space>
      </Col>
      <Col xs={24} sm={8} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ? "Pasillo:" : "Corridor:"}</span>
          <Input
            allowClear
            placeholder={language === "es" ?"Pasillo" : "Corridor"}
            value={corridorName}
            onChange={(e) => setCorridorName(e.target.value)}
          />
        </Space>
      </Col>
      <Col xs={24} sm={8} md={8} lg={6}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <span>{language === "es" ?"Mostrar:":"Show:"}</span>

          <Radio.Group
            name="radiogroup"
            defaultValue={1}
            options={[
              { value: 1, label: language === "es" ? 'Todos' : "All" },
              { value: 2, label: language === "es" ? 'Explorados' : "Explored" },
              { value: 3, label:<>{language === "es" ? 'Sin explorar' : "Unexplored"}<Tooltip title={language === "es" ?"Sin explorar: Lectura < 10%":"Unexplored: Reading < 10%"}>  <InfoCircleOutlined style={{fontSize:"1.3vh"}}/> </Tooltip></>},
            ]}
            onChange={(e) => setToShow(e.target.value)}
          />
        </Space>
      </Col>

      <Col xs={24} sm={8} md={8} lg={3} >
        <Button type="primary" onClick={props.handleExpandAll}>
          {language === "es" ? "Expandir Todo" : "Expand All"}
        </Button>

      </Col>
      <Col xs={24} sm={8} md={8} lg={3} >

        <Button
          type="default"
          onClick={props.handleCollapseAll}
          style={{ marginLeft: "8px" }}
        >
          {language === "es" ? "Colapsar Todo" : "Collapse All"}
        </Button>
      </Col>
    </Row>
  );
};

export default CorridorsControls;
