import { Table } from "antd";
import { CorridorSummary } from "../../../../../../models/Corridor";
import {  useState } from "react";
import { useLanguage } from "../../../../../../context/LanguageContext";

interface CorridorsTableProps {
  corridors: CorridorSummary[];
}

const CorridorsTable = (props: CorridorsTableProps) => {
  const { language } = useLanguage();
  const [pageSize, setPageSize] = useState<number>(10);
  const columns = [
    {
      title: language === "es" ? "Nombre del rack" :"Corridor Name",
      key: "name",
      render: (item: CorridorSummary) => item.name,
    },
    {
      title: language === "es" ? "Posiciones Totales" :"Total Positions",
      key: "positions",
      render: (item: CorridorSummary) => item.positions.toString(),
    },
    {
      title: language === "es" ? "Posiciones vacías" : "Empty Positions",
      key: "empty_positions",
      render: (item: CorridorSummary) => item.empty_positions.toString(),
    },
    {
      title: language === "es" ? "Posiciones llenas" :"Full Positions",
      key: "full_positions",
      render: (item: CorridorSummary) => item.full_positions.toString(),
    },
    {
      title: language === "es" ? "Posiciones Inexploradas" : "Unexplored Positions",
      key: "unknown_positions",
      render: (item: CorridorSummary) => item.unknown_positions.toString(),
    },
    {
      title: language === "es" ? "Plantilla de Rack" : "Rack Template",
      key: "rack_template",
      render: (item: CorridorSummary) => item.rack_template,
    }
  ];

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
  };

  const paginationConfig = {
    pageSize: pageSize,
    total: props.corridors.length,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"],
    onChange: (page: number, pageSize: number | undefined) => {
      if (pageSize) {
        setPageSize(pageSize);
      }
    },
  };

  return (
    <Table
      columns={columns}
      dataSource={props.corridors}
      rowKey="id"
      scroll={{ x: true }}
      size="small"
      pagination={paginationConfig}
      showSorterTooltip={true}
      onChange={handleTableChange}
    />
  );
};

export default CorridorsTable;