import axios, { AxiosResponse } from "axios";
import UserService from "./UserService";


const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "permissions/";

export default class PermissionService {
    public static async getSweepWritePermission(): Promise<Boolean> {
        try {
          const response: AxiosResponse<Boolean> = await axios.get(
            API_BASE_URL + API_RESOURCE +"sweep_write_from_sweep_read",
            {
              headers: UserService.getAuthHeader(),
            }
          );
          console.log(response.data)
          return response.data;
        } catch (error) {
          console.error(error);
          return false;
        }
      }
    public static async getSupervisionWritePermission(): Promise<Boolean> {
        try {
          const response: AxiosResponse<Boolean> = await axios.get(
            API_BASE_URL + API_RESOURCE +"supervision_read_from_sweep_read ",
            {
              headers: UserService.getAuthHeader(),
            }
          );
          console.log(response.data)
          return response.data;
        } catch (error) {
          console.error(error);
          return false;
        }
      }
}
