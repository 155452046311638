import React, { useCallback, useEffect, useState } from "react";
import { Button, Popover, Spin, Modal, Col, Row, Card } from "antd";
import CorridorService from "../../../../../../services/CorridorService";
import { CorridorShow } from "../../../../../../models/Corridor";
import { SupervisionDetails, supervisionDetailsPosition } from "../../../../../../models/Supervision";
import ImageDisplayer from "../../../../../utils/ImageDisplayer";
import SweepPictureService from "../../../../../../services/PictureService";
import SweepThumbnailService from "../../../../../../services/ThumbnailService";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

// Modal component to display images
const ImageModal: React.FC<ImageModalProps> = ({ visible, sweepIdx, modalPosition, onClose }) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose={true}
      title={`Position: ${modalPosition}`}
    >
      <Row justify="space-around" align="middle">
        <Col span="100px">
          <Card style={{ width: "230px", textAlign: "center", height: "100" }}>
            <ImageDisplayer
              fetchFunction={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return SweepPictureService.getSweepPositionPicture(parseInt(sweepIdx), paddedPositionLabel);
              }}
              lowfetchFuntion={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return SweepThumbnailService.getSweepPositionThumbnail(parseInt(sweepIdx), paddedPositionLabel);
              }}
              height={"100px"}
            />
          </Card>
        </Col>
        <Col span="100px">
          <Card style={{ width: "230px", textAlign: "center", height: "100" }}>

            <ImageDisplayer
              fetchFunction={() => {
                var paddedPositionLabel = modalPosition.padStart(5, ' ');
                return SweepPictureService.getSweepPalletPicture(parseInt(sweepIdx), paddedPositionLabel);
              }}
              lowfetchFuntion={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return SweepThumbnailService.getSweepPalletThumbnail(parseInt(sweepIdx), paddedPositionLabel);
              }}
              height={"100px"}
            />
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};
interface ImageModalProps {
  visible: boolean;
  sweepIdx: string;
  modalPosition: string;
  onClose: () => void;
}

interface GridProps {
  corridorIdx: string | undefined;
  supervisiondetails: SupervisionDetails;
  sweepIdx: string;
}
type ButtonSize = 'small' | 'middle' | 'large';
const CorridorHistoryMap: React.FC<GridProps> = ({ corridorIdx, sweepIdx, supervisiondetails }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [corridor, setCorridor] = useState<CorridorShow>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalPosition, setModalPosition] = useState<string>("");
  const screens = useBreakpoint();

  const buttonSize: ButtonSize = !screens.lg ? 'large' : 'small';

  const updateContent = useCallback(() => {
    if (corridorIdx) {
      CorridorService.getOne(corridorIdx.toString()).then((corridor) => {
        if (corridor === null) {
          return;
        }
        setCorridor(corridor);
        setLoading(false);
      });
    }
  }, [corridorIdx]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 500000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const handlePopoverClick = useCallback((supervisionPosition: supervisionDetailsPosition | undefined) => {
    if (supervisionPosition) {
      setModalPosition(supervisionPosition.position_label)
      setModalVisible(true);
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          overflow: "scroll"
        }}
      >
        {corridor && corridor.rack_template && corridor.rack_template.level_range && corridor.rack_template.column_range && (
          Array.from({ length: corridor.rack_template.level_range[1] - corridor.rack_template.level_range[0] + 1 }, (_, i) => (
            <div
              key={`row-${i}`}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {corridor?.rack_template && Array.from({ length: corridor.rack_template.column_range[1] - corridor.rack_template.column_range[0] + 1 }, (_, j) => {
                let colText = corridor.rack_template?.column_range ? String(j + corridor.rack_template.column_range[0]).padStart(2, "0") : '';
                if (corridor.rack_template?.orientation === "right") {
                  colText = corridor.rack_template?.column_range ? String(corridor.rack_template.column_range[1] - j).padStart(2, "0") : '';
                }
                const levelText = corridor.rack_template?.level_range ? String(i + corridor.rack_template.level_range[0]) : '';
                const positionText = `${corridor.name}${colText}${levelText}`;
                const matchingPosition = supervisiondetails.positions.find((position) => position.position_label === positionText);
                let palletLabel = matchingPosition?.pallet_label ?? "Empty Position";
                palletLabel = matchingPosition?.state === "unknown" ? "Unexplored" : palletLabel;
                const popoverContent = `Pallet Label: ${palletLabel}`;
                const buttonStyle = {
                  margin: "2px",
                  backgroundColor: matchingPosition ? (matchingPosition.state === "full" ? "#4CAF50" : matchingPosition.state === "empty" ? "#a0d911" : matchingPosition.state === "unknown" ? "#FF5252" : "#FFC107") : "#bfbfbf",
                  fontSize: buttonSize === 'large' ? '16px' : '12px',
                };
                return (
                  <Popover
                    title={(screens.lg) ? `Position: ${positionText}` : ""}
                    key={`tooltip-${i}-${j}`}
                    content={<p>{popoverContent}</p>}
                  >
                    <Button
                      ghost
                      key={`button-${i}-${j}`}
                      type={"primary"}
                      size={buttonSize}
                      style={buttonStyle}
                      onClick={() => handlePopoverClick(matchingPosition)}
                    ></Button>
                  </Popover>
                );
              })}
            </div>
          )).reverse()
        )}
        <ImageModal
          visible={modalVisible}
          modalPosition={modalPosition}
          sweepIdx={sweepIdx}
          onClose={() => setModalVisible(false)}
        />
      </Row>
    </Spin>
  );
};

export default CorridorHistoryMap;
