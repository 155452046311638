import axios, { AxiosResponse } from "axios";
import {  VideoPosition } from "../models/Sweep";
import UserService from "./UserService";

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "video_revision/sweeps/positions/videos/";

export default class VideoRevisionService {
  public static async getPositions(id: string): Promise<VideoPosition[] | null> {
    try {
      const response: AxiosResponse<VideoPosition[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + id ,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const positions = response.data.map((positions) => VideoPosition.from(positions));
      return positions;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async getVideo(
    sweepId: string,
    videoIdx: string
  ): Promise<string | null> {
    try {
      const response: AxiosResponse<Blob> = await axios.get(
        `${API_BASE_URL}${API_RESOURCE}${sweepId}/${videoIdx}`,
        {
          headers: UserService.getAuthHeader(),
          responseType: "blob", // Expecting video as binary data
        }
      );
      const videoBlob = new Blob([response.data], { type: "video/mp4" });
      return URL.createObjectURL(videoBlob); // Return the video URL
    } catch (error: unknown) {
      console.error("Error fetching video", error);
      return null;
    }
  }
}