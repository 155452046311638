import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Card, Spin, Divider } from "antd";
import CorridorMap from "./CorridorMap";
import WarehouseService from "../../../../../../services/WarehouseService";
import CorridorColorReferences from "./CorridorColorReferences";
import { CorridorSummary } from "../../../../../../models/Corridor";
import { useNavigate } from "react-router-dom";

const { Panel } = Collapse;

interface CorridorCollapseProps {
  warehouseId: string;
  isMobile:boolean
}

const WarehouseMap: React.FC<CorridorCollapseProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [corridors, setCorridors] = useState<CorridorSummary[]>([]);
  const navigate=useNavigate()
  const updateContent = useCallback(() => {
    // setLoading(true);
    WarehouseService.getOne(props.warehouseId,navigate).then((res) => {
      if (res === null) {
        return;
      }
      console.log(res.corridors);
      setCorridors(res.corridors);
      setLoading(false);
    });
  }, [props.warehouseId,navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <Spin spinning={loading}>
      <CorridorColorReferences />
      <Divider orientation="center">Corridors</Divider>
      <Collapse accordion destroyInactivePanel>
        {corridors.map((corridor) => (
          <Panel header={`Corridor ${corridor.name}`} key={corridor.name}>
            <Card>
              <CorridorMap
                isMobile={props.isMobile}
                warehouseId={props.warehouseId}
                corridorId={corridor.idx}
              />
            </Card>
          </Panel>
        ))}
      </Collapse>
    </Spin>
  );
};

export default WarehouseMap;
