import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Card, Spin, Divider } from "antd";
import CorridorMap from "./CorridorMap";
import WarehouseService from "../../../../../../services/WarehouseService";
import CorridorColorReferences from "./CorridorColorReferences";
import Corridor from "../../../../../../models/Corridor";
import { useLanguage } from "../../../../../../context/LanguageContext";
import Position from "../../../../../../models/Position";

const { Panel } = Collapse;

interface CorridorCollapseProps {
  warehouseId: string;
  isMobile:boolean;
}

const WarehouseMap: React.FC<CorridorCollapseProps> = (props) => {
  const { language } = useLanguage();
  const [loading, setLoading] = useState<boolean>(true);
  const [corridors, setCorridors] = useState<Corridor[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const startsWithCorridorName = (positions:Position[],corridor:string)=>{return positions.filter((position) => position.position_label.startsWith(corridor));}
  const updateContent = useCallback(() => {
    // setLoading(true);
    WarehouseService.getCorridors(props.warehouseId).then((corridors)=>{
      if(corridors===null){
        return
      }
      WarehouseService.getPositions(props.warehouseId).then((res) => {
        if (res === null) {
          return;
        }
        setCorridors(corridors)
        setPositions(res);
        setLoading(false);
      })
    });
  }, [props.warehouseId]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <Spin spinning={loading}>
      <CorridorColorReferences />
      <Divider orientation="center">{language==="es"?"Racks":"Corridors"}</Divider>
      <Collapse >
        {corridors.map((corridor) => (
          <Panel header={language==="es"?`Rack ${corridor.name}`:`Corridor ${corridor.name}`} key={corridor.name}>
            <Card>
              <CorridorMap
                isMobile={props.isMobile}
                warehouseId={props.warehouseId}
                corridorInfo={corridor}
                corridorPositions={startsWithCorridorName(positions,corridor.name)}
              />
            </Card>
          </Panel>
        ))}
      </Collapse>
    </Spin>
  );
};

export default WarehouseMap;
