import { Spin, Table, Tag } from "antd";
import { SweepPositionShow } from "../../../../../../models/Sweep";
import {   useState } from "react";
import ImageDisplayer from "../../../../../utils/ImageDisplayer";
import SweepPictureService from "../../../../../../services/PictureService";
import SweepThumbnailService from "../../../../../../services/ThumbnailService";
import { useLanguage } from "../../../../../../context/LanguageContext";

interface SummaryTableProps {
  sweepId: number
  positions: SweepPositionShow[];
  showPictures:boolean;
}

const SummaryTable = (props: SummaryTableProps) => {
  const { language } = useLanguage();
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading] = useState<boolean>(false);
  let columns = [
    {
      title: language==="es"?"Etiqueta de Posición":"Position Label",
      key: "position_label",
      render: (item: SweepPositionShow) => {
        return (
          <Tag>{item.position_label}</Tag>
        );
      },
    },
    {
      title: language==="es"?"Imagen de Posición":"Position Image",
      key: "image_position",
      render: (item: SweepPositionShow) => {
        return (
          <ImageDisplayer
            position_label = {item.position_label}
            fetchFunction={() => {
              const paddedPositionLabel = item.position_label.padStart(5, " ");
              return SweepPictureService.getSweepPositionPicture(
                props.sweepId,
                paddedPositionLabel
              );
            }}
            lowfetchFuntion={(abortSignal: AbortSignal) => {
              const paddedPositionLabel = item.position_label.padStart(5, " ");
              return SweepThumbnailService.getSweepPositionThumbnail(
                props.sweepId,
                paddedPositionLabel,
                abortSignal
              );
            }}
            height={"50px"}
            width={"88.88px"}
          />
        );
      },
    },
    {
      title: language==="es"?"Etiqueta de Pallet":"Pallet Label",
      key: "pallet_label",
      render: (item: SweepPositionShow) => 
        <Tag>{item.state==="empty"?language==="es"?"VACÍA":"EMPTY":item.pallet_label}</Tag>
  ,
    },
    {
      title: language==="es"?"Imagen de Pallet":"Pallet Image",
      key: "image_pallet",
      render: (item: SweepPositionShow) => {
        return (
          <ImageDisplayer
            position_label = {item.position_label}
            fetchFunction={() => {
              const paddedPositionLabel = item.position_label.padStart(5, " ");
              return SweepPictureService.getSweepPalletPicture(
                props.sweepId,
                paddedPositionLabel
              );
            }}
            lowfetchFuntion={(abortSignal: AbortSignal) => {
              const paddedPositionLabel = item.position_label.padStart(5, " ");
              return SweepThumbnailService.getSweepPalletThumbnail(
                props.sweepId,
                paddedPositionLabel,
                abortSignal
              );
            }}
            height={"50px"}
            width={"88.88px"}
          />
        );
      },
    },
    {
      title: language==="es"?"Estado":"State",
      key: "state",
      render: (item: SweepPositionShow) => {
        if(language==="es"){
          switch(item.state){
            case "full":
              return "ocupada"
            case "empty":
              return "vacía"
            case "unknown":
              return "desconocida"
            default :
              return "inválida"
          }
        }else{return item.state}},
    }
  ];

  if (!props.showPictures) {
    columns = columns.filter(
      (column) => column.key !== "image_position"
    );
  }

  const paginationConfig = {
    pageSize: pageSize,
    total: props.positions.length,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"],
    onChange: (page: number, pageSize: number | undefined) => {
      if (pageSize) {
        setPageSize(pageSize);
      }
    },
  };


  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={props.positions}
        rowKey="id"
        scroll={{ x: true }}
        size="small"
        pagination={paginationConfig}
        showSorterTooltip={true}
      />
    </Spin>
  );
};

export default SummaryTable;
