import { Button, Card, Col, Collapse, Divider, Empty, Progress, Row, Spin, Statistic, Tag } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CorridorInfo from "./../CorridorInfo";
import SupervisionService from "../../../../../../services/SupervisionService";
import Supervision, { SupervisionWarehouse } from "../../../../../../models/Supervision";
import { CaretLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useLanguage } from "../../../../../../context/LanguageContext";
import CorridorsControls from "../CorridorsControls";

const CorridorHistory: React.FC = () => {
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);
  const { warehouseIdx, sweepIdx } = useParams();
  const [supervisions, setsupervisions] = useState<Supervision[]>([]);
  const [supervisionWarehouse, setSupervisionWarehouse] = useState<SupervisionWarehouse>();
  const [parentSizes, setParentSizes] = useState<Record<number, { width: number, height: number }>>({});
  const [corridorsLoading, setCorridorsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const screens = useBreakpoint();
  const [corridorFilter, setCorridorFilter] = useState<string>("");
  const [deviceFilter, setDeviceFilter] = useState<string[]>([]);
  const [toShowFilter, setToShowFilter] = useState<number>(1);
  

  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const { Panel } = Collapse;
  const handleExpandAll = () => {
    const keys = filteredSupervisions.map((supervision) => supervision.corridor_name);
    setActiveKeys(keys);
  };

  const handleCollapseAll = () => {
    setActiveKeys([]);
  };
   const onApplyFilters = useCallback(
     (
       device: string[],
       corridor: string,
       toShowFilter: number
     ) => {
       setDeviceFilter(device)
       setCorridorFilter(corridor);
       setToShowFilter(toShowFilter);
     },
     []
   );
   const filteredSupervisions = useMemo(() => {
     let filtered = supervisions;
     if (deviceFilter.length > 0) {
       filtered = filtered.filter((item) =>
         deviceFilter.includes(item.device_name))
     }
     if (corridorFilter !== "") {
       filtered = filtered.filter((entry) =>
         entry.corridor_name
           .toLocaleUpperCase()
           .includes(corridorFilter.toLocaleUpperCase())
 
       );
     }
     if (toShowFilter!==1) {
      if(toShowFilter!==3){
        filtered = filtered.filter((entry) => entry.reading_percentage > 0.1);
      }else{
        filtered = filtered.filter((entry) => entry.reading_percentage < 0.1);
      }
     }
     return filtered;
   }, [
     supervisions,
     deviceFilter,
     corridorFilter,
     toShowFilter
   ]);

  const updateContent = useCallback(() => {
    if (warehouseIdx === undefined || sweepIdx === undefined) return;
    SupervisionService.getSupervisionHistorySummary(parseInt(warehouseIdx), Number(sweepIdx), navigate)
      .then((supervisions) => {
        setsupervisions(supervisions || []);
        setCorridorsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch supervisions:", error);
        setsupervisions([]);
        setCorridorsLoading(false);
      });
    SupervisionService.getSupervisionHistory(parseInt(sweepIdx), navigate)
      .then((supervisionWarehouse) => {
        setSupervisionWarehouse(supervisionWarehouse || undefined);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch warehouses:", error);
        setLoading(false);
      });
  }, [warehouseIdx, sweepIdx, navigate]);


  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 300000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const handleResize = (idx: number, entry: ResizeObserverEntry) => {
    setParentSizes((prevSizes) => ({
      ...prevSizes,
      [idx]: {
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      },
    }));
  };

  return (
    <Card>
      <Spin spinning={loading}>
        {supervisionWarehouse && <Card
          title={supervisionWarehouse.warehouse_name}
          extra={<Link to={`/supervision/history/${warehouseIdx}`} >
            <Button
              type="primary"
              shape="round"
              icon={<CaretLeftOutlined />}
            >
              {language === "es" ? "Atrás" : "Back"}
            </Button>
          </Link>}>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8}>
              <Row gutter={[10, 10]}>
                <Col span={24} style={{ textAlign: 'center' }} >
                  <Card bordered={false}>
                    <Statistic
                      title={<h3>{language === "es" ? "Número de Conteo" : "Counting number"} </h3>}
                      value={supervisionWarehouse.counting_number}
                      groupSeparator=""
                    />
                  </Card>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }} >
                  <Card bordered={false}>
                    <Statistic
                      title={<h3>{language === "es" ? "Cantidad de \n Dispositivos" : "Devices counting"} </h3>}
                      value={supervisionWarehouse.devices_count}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={screens.xs ? 24 : 8} style={{ textAlign: 'center' }}>
              <Card title={language === "es" ? "Lecturas" : "Readings"}>
                <Row gutter={[10, 15]}>
                  <Col span={24}>
                    <Progress type="circle" percent={parseFloat((supervisionWarehouse.reading_percentage * 100).toFixed(2))}
                      format={(percent) => `${percent}%`} size={100} success={{ progress: -1 }} />
                  </Col>
                  <Col span={24}>
                    <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{`${supervisionWarehouse.read_positions} / ${supervisionWarehouse.total_count}`}</b>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={screens.xs ? 24 : 8} style={{ textAlign: 'center' }}>
              <Card title={language === "es" ? "Efectividad" : "Effective"}>
                <Row gutter={[10, 15]}>
                  <Col span={24}>
                    <Progress type="circle" percent={parseFloat((supervisionWarehouse.effective_percentage * 100).toFixed(2))}
                      format={(percent) => `${percent}%`} size={100}
                      strokeColor={supervisionWarehouse.effective_percentage > 0.75 ? "#1677ff" : supervisionWarehouse.effective_percentage > 0.50 ? "#fadb14" : "#f5222d"} />
                  </Col>
                  <Col span={24}>
                    <b style={{ fontSize: screens.lg ? "0.90vw" : screens.md ? "1.2vw" : screens.sm ? "1.5vw" : "3vw" }}>{`${supervisionWarehouse.effective_readings} / ${supervisionWarehouse.read_positions}`}</b>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>}
        <Divider >{language === "es" ? "Racks" : "Corridors"}</Divider>
        <Spin spinning={corridorsLoading && !loading}>
          <CorridorsControls onApply={onApplyFilters} devicesNames={Array.from(new Set(supervisions.map((item) => item.device_name)))} handleExpandAll={handleExpandAll} handleCollapseAll={handleCollapseAll} />
          <Divider />
          {supervisions &&
            <Row gutter={[16, 16]} style={{ overflow: "auto" }}>
              {filteredSupervisions.map((supervision, i) => (
                <Col key={i} xs={24} sm={24} md={12} lg={8} style={{ overflow: "auto" }}>
                  <Collapse activeKey={activeKeys}
                  onChange={(keys) => setActiveKeys(keys as string[])}>
                  <Panel header={<Row><Col span={2} >{supervision.corridor_name}</Col><Col span={20}>{supervision.reading_percentage>0.1?<Progress showInfo={false} percent={parseFloat((supervision.reading_percentage * 100).toFixed(2))}/>:
                    <Tag color="gold" style={{marginLeft:"10px"}}>{language==="es"?"Sin explorar":"Unexplored"}</Tag>}</Col></Row>
                    } key={supervision.corridor_name} extra={<Link to={`${supervision.corridor_idx}`} >
                          <Button
                            type="primary"
                            size="small"
                            shape="round"
                            icon={<InfoCircleOutlined />}
                          >
                            {language === "es" ? "Detalles" : "Details"}
                          </Button>
                        </Link>}>
                      {/* <Card
                        ref={(el) => {
                          if (el) {
                            const observer = new ResizeObserver((entries) => {
                              for (let entry of entries) {
                                if (entry.target === el) {
                                  handleResize(i, entry);
                                }
                              }
                            });
                            observer.observe(el);
                          }
                        }}
                        style={{ minHeight: "270px", overflow: "auto" }}
                      > */}
                        {supervision.corridor_idx ? (
                          <CorridorInfo
                            parentWidth={parentSizes[i]?.width || 0}
                            parentHeight={parentSizes[i]?.height || 0}
                            supervision={supervision}
                            loading={loading}
                          />
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      {/* </Card> */}
                    </Panel>
                  </Collapse>
                </Col>
              ))}
            </Row>}
        </Spin>
      </Spin>
    </Card>)
}

export default CorridorHistory;