import { WarehouseBase } from "./Warehouse";

export default class UserModel {
  id: string = "";
  idx: number = 0;
  username: string = "";
  email: string = "";
  first_name: string = "";
  last_name: string = "";
  roles: string[] = [];

  public static from(json: any) {
    return Object.assign(new UserModel(), json) as UserModel;
  }
}
export class PermissionShow {
  idx: number = 0
  resource: string = "";
  read: boolean = false;
  write: boolean = false;
  public static from(json: any) {
    return Object.assign(new PermissionShow(), json) as PermissionShow;
  }
}
export class RoleShow {
  idx: number = 0;
  name: string = "";
  permissions: PermissionShow[] = [];
  public static from(json: any) {
    return Object.assign(new RoleShow(), json) as RoleShow;
  }
}
export class UserShow {
  id: string = "";
  idx: number = 0;
  name: string = "";
  role: RoleShow = new RoleShow();
  warehouses: WarehouseBase[] = [];

  public static from(json: any) {
    return Object.assign(new UserShow(), json) as UserShow;
  }
}
