import React from "react";
import { Tag, Divider } from "antd";

const CorridorColorReferences = () => {
  return (
    <div style={{ textAlign: "center" }}>
    <Divider orientation="center">Color References</Divider>
    <Tag color="#4CAF50">Full</Tag>
    <Tag color="#a0d911">Empty</Tag>
    <Tag color="#FF5252">Unexplored</Tag>
    <Tag color="#FFC107">Invalid</Tag>
    <Tag color="#bfbfbf" style={{ color: "#333333" }} >Ignored</Tag>
  </div>
    
  );
};

export default CorridorColorReferences;