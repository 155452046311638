import { Input, Col, Row, Space} from "antd";
import { useState, useCallback, useEffect } from "react";

interface PositionsControlsProps {
  onApply: (
    position: string,
    pallet: string,
    corridor: string,
    column: string,
    level: string,
  ) => void;
}

const PositionsControls = (props: PositionsControlsProps) => {
  const [position, setPosition] = useState<string>("");
  const [pallet, setPallet] = useState<string>("");
  const [corridor, setCorridor] = useState<string>("");
  const [column, setColumn] = useState<string>("");
  const [level, setLevel] = useState<string>("");


  const onApply = useCallback(() => {
    props.onApply(position, pallet, corridor, column, level);
  }, [props, position, pallet, corridor, column, level]);

  useEffect(() => {
    onApply();
  }, [onApply]);

  return (
    <Row gutter={20} align="middle">
      <Col span={4}>
        <Space>
          Position
          <Input
            placeholder="Position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            // style={{ width: '80%' }}
          />
        </Space>
      </Col>
      <Col span={4}>
        <Space>
          Pallet
          <Input
            placeholder="Pallet"
            value={pallet}
            onChange={(e) => setPallet(e.target.value)}
            // style={{ width: '80%' }}
          />
        </Space>
      </Col>
      <Col span={3}>
        <Space>
          Corridor
          <Input
            // placeholder="Corridor"
            value={corridor}
            onChange={(e) => setCorridor(e.target.value)}
            // style={{ width: '40%' }}
          />
        </Space>
      </Col>
      <Col span={3}>
        <Space>
          Column
          <Input
            // placeholder="Column"
            value={column}
            onChange={(e) => setColumn(e.target.value)}
            // style={{ width: '40%' }}
          />
        </Space>
      </Col>
      <Col span={3}>
        <Space>
          Level
          <Input
            // placeholder="Level"
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            // style={{ width: '40%' }}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default PositionsControls;
