import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card, Spin, Tabs, TabsProps } from "antd";
import { useCallback, useEffect, useState } from "react";

import SweepService from "../../../../../services/SweepService";
import { SweepPositionShow } from "../../../../../models/Sweep";


import NotReadRevision from "./tab2/NotReadRevision";
import EmptyRevision from "./tab3/EmptyRevision";
import FullRevision from "./tab4/FullRevision";
import UnexploredRevision from "./tab1/UnexploredRevision";


const SweepRevision = () => {
  const { sweepId } = useParams();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [countingNumber, setCountingNumber] = useState<string>("");
  const [positions, setPositions] = useState<SweepPositionShow[]>([]);
  const [sweepStatus, setSweepStatus] = useState<string>("")
  let tabId = searchParams.get("tab") || "1";
  let positionLabel = searchParams.get("positionLabel") || "0";

  const trimSpacesInPositionLabels = (sweepPositions: SweepPositionShow[]): SweepPositionShow[] => {
    return sweepPositions.map(position => {
      position.position_label = position.position_label.trim();
      return position;
    });
  };
  const navigate=useNavigate()
  const updateContent = useCallback(() => {
    if (sweepId) {
      setLoading(true);
      SweepService.getOne(sweepId,navigate).then((sweep) => {
        if (sweep == null) {
          return;
        }
        setSweepStatus(sweep.status)
        setCountingNumber(sweep.counting_number)
        var positions_ = trimSpacesInPositionLabels(sweep.positions)
        setPositions(positions_)
        setLoading(false);
      });
    }
  }, [sweepId,navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 6000000);
    return () => clearInterval(interval);
  }, [updateContent]);


  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Unexplored",
      children: (sweepId &&
        <UnexploredRevision
          sweepStatus = {sweepStatus}
          onUpdateContent={updateContent}
          sweepId={sweepId}
          positions={positions}
        />
      ),
    },
    {
      key: "2",
      label: "Invalid",
      children: (sweepId &&
        <NotReadRevision
          sweepStatus = {sweepStatus}
          onUpdateContent={updateContent}
          sweepId={sweepId}
          positionLabel={positionLabel}
          positions={positions}
        />
      ),
    },
    {
      key: "3",
      label: "Empty",
      children: (sweepId &&
        <EmptyRevision
          sweepStatus = {sweepStatus}
          onUpdateContent={updateContent}
          sweepId={sweepId}
          positionLabel={positionLabel}
          positions={positions}
        />
      ),
    },
    {
      key: "4",
      label: "Full",
      children: (sweepId &&
        <FullRevision
          sweepStatus = {sweepStatus}
          onUpdateContent={updateContent}
          sweepId={sweepId}
          positionLabel={positionLabel}
          positions={positions}
        />
      ),
    },
  ];
  return (
    <>
      <Spin spinning={loading}>
        <Card title={"Sweep: " + countingNumber}>
          <Tabs
            items={tabs}
            activeKey={tabId}
            onTabClick={(activeKey) => {
              setSearchParams({ tab: activeKey });
            }}
          />
        </Card>
      </Spin>
    </>
  );
};

export default SweepRevision;
