import { useNavigate, useParams } from "react-router-dom";
import { Breakpoint, Button, Card, notification, Space, Spin, Table, Tag, Tooltip } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import SweepService from "../../../../../services/SweepService";
import { SweepInfo } from "../../../../../models/Sweep";
import UserContext from "../../../../../context/UserContext";
import CorridorStateBase from "../../../../../models/CorridorState";
import CorridorStatesService from "../../../../../services/CorridorStatesService";
import { CheckCircleOutlined, InfoCircleOutlined, SyncOutlined } from "@ant-design/icons";

const CorridorsStatus = () => {
  const isMobile = useContext(UserContext).isMobile;
  const { sweepId } = useParams();

  const [corridorStates, setCorridorStates] = useState<CorridorStateBase[]>([]);
  const [sweep, setSweep] = useState(new SweepInfo());
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate()
  const updateContent = useCallback(() => {
    if (sweepId) {
      CorridorStatesService.getAll(sweepId)
        .then((data) => {
          if (data) {
            setCorridorStates(data);
          }
          SweepService.getInfo(sweepId, navigate).then((sweep) => {
            if (sweep == null) {
              return;
            }
            setSweep(sweep);
            setLoading(false);
          }
          )
        })
        .catch((error) => {
          console.error("Error fetching corridor states:", error);
        });
    }
  }, [sweepId, navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    return () => clearInterval(interval);
  }, [updateContent]);


  const export_corridor = (corridorState: CorridorStateBase) => {
    setLoading(true);
    CorridorStatesService.updateOne(corridorState.idx).then(() => {
      notification.success({
        message: <>Corridor {corridorState.corridor_name} completed!</>,
        duration: 10,
      })
      updateContent();
    })
  }

  const columns = [
    {
      title: "",
      key: "idx",
      width: 50,
      responsive: ["lg"] as Breakpoint[],
      render: (item: CorridorStateBase) => item.idx,
    },
    {
      title: "Corridor Name",
      key: "corridor_name",
      width: 120,
      render: (item: CorridorStateBase) => item.corridor_name,
    },
    {
      title: "Device",
      key: "device",
      width: 120,
      render: (item: CorridorStateBase) => item.device_name,
    },
    {
      title: (
        <Tooltip title="Number of readings uploaded from the device">
          <span>
            Readings Uploaded <InfoCircleOutlined style={{ marginLeft: 4 }} />
          </span>
        </Tooltip>
      ),
      key: "readings_uploaded",
      width: 120,
      render: (item: CorridorStateBase) => `${item.total_readings - item.pending_readings_to_upload}/${item.total_readings}`,
    },
    {
      title: (
        <Tooltip title="Number of uploaded readings that are already processed in the cloud">
          <span>
            Readings Processed <InfoCircleOutlined style={{ marginLeft: 4 }} />
          </span>
        </Tooltip>
      ),
      key: "readings_processed",
      width: 120,
      render: (item: CorridorStateBase) => `${item.total_readings - item.pending_readings_to_upload - item.pending_readings_to_process}/${item.total_readings - item.pending_readings_to_upload}`,
    },
    {
      title: (
        <Tooltip title="Number of processed, filtered and unique readings that are already uploaded to JD Edwards">
          <span>
            Readings to JD <InfoCircleOutlined style={{ marginLeft: 4 }} />
          </span>
        </Tooltip>
      ),
      key: "readings_uploaded",
      width: 120,
      render: (item: CorridorStateBase) => `${item.unique_positions_read - item.pending_positions_to_jd}/${item.unique_positions_read}`,
    },
    {
      title: "Driver Status",
      key: "driver_status",
      width: 120,
      render: (item: CorridorStateBase) => (
        <>
          {item.device_status === "in_progress" ? (
            <Tag icon={<SyncOutlined spin />} color="processing">In Progress</Tag>
          ) : (
            <Tag icon={<CheckCircleOutlined />} color="success">Done</Tag>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      responsive: ["lg"] as Breakpoint[],
      render: (item: CorridorStateBase) => (
        <>
          {!item.exported && item.pending_readings_to_upload === 0 && item.pending_readings_to_process === 0 && item.pending_positions_to_jd === 0 && item.device_status === "done" ? (
            <Button type="primary"
              shape="round"
              onClick={() => export_corridor(item)}>
              Export
            </Button>
          ) : item.exported ? (
            <Tag icon={<CheckCircleOutlined />} color="success">Exported</Tag>
          ) : item.pending_readings_to_upload !== 0 ? (
            <Tag icon={<SyncOutlined spin />} color="processing">Pending Readings from device</Tag>
          ) : item.pending_readings_to_process !== 0 ? (
            <Tag icon={<SyncOutlined spin />} color="processing">Pending Readings to process</Tag>
          ) : item.pending_positions_to_jd !== 0 ? (
            <Tag icon={<SyncOutlined spin />} color="processing">Pending Readings to JD</Tag>
          ) : (
            <Tag icon={<SyncOutlined spin />} color="processing">Waiting Driver</Tag>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={loading}>
        <Card title={<Space>{sweep.counting_number ? "Sweep: " + sweep.counting_number + " - " + sweep.warehouse_name : ""}<Tag color={sweep.status === "processing" ? "blue" : sweep.status === "processed" ? "green" : "red"}>
          <h6 style={{ margin: 0, textAlign: "center", width: "100%" }}>
            {sweep.timestamp_start.split(" ")[0]}
          </h6>
        </Tag></Space>}
          style={{
            margin: isMobile ? '10px' : '20px',
            padding: isMobile ? '10px' : '20px',
          }}
          extra={
            <></>
          }>

          <Table
            columns={columns}
            dataSource={corridorStates}
            rowKey="id"
            size="small"
          />

        </Card>
      </Spin>
    </>
  );
};

export default CorridorsStatus;
