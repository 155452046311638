import axios, { AxiosResponse } from "axios";
import UserService from "./UserService";
import Settings from "../models/Settings";

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "settings/";

export default class SettingsService {
    public static async getSettings(): Promise<Settings|null> {
        try {
          const response: AxiosResponse<Settings> = await axios.get(
            API_BASE_URL + API_RESOURCE,
            {
              headers: UserService.getAuthHeader(),
            }
          );
          return response.data;
        } catch (error) {
          console.error(error);
          return null;
        }
      }
    public static async uptdateSettings(): Promise<string |null> {
        try {
          const response: AxiosResponse<string> = await axios.put(
            API_BASE_URL + API_RESOURCE,
            {
              headers: UserService.getAuthHeader(),
            }
          );
          return response.data;
        } catch (error) {
          console.error(error);
          return null;
        }
      }
}