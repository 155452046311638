export default class CorridorStateBase {
  idx: string = "";
  device_name: string = "";
  corridor_name: string = "";
  device_status: string = "";
  pending_readings_to_upload: number = 0;
  pending_readings_to_process: number = 0;
  pending_positions_to_jd: number = 0;
  total_readings: number = 0;
  unique_positions_read: number = 0;
  exported: boolean = false;

  public static from(json: any) {
    return Object.assign(new CorridorStateBase(), json) as CorridorStateBase;
  }
}
