import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Default: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/sweeps");
  });

  return (
    <>
    </>
  );
};

export default Default;
