import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Button, Card, Divider, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SweepBase from '../../../../models/Sweep';
import SweepService from '../../../../services/SweepService';
import SweepsTable from './subcomponents/SweepsTable';
import SweepsCreate from './subcomponents/SweepsCreate';
import WarehouseService from '../../../../services/WarehouseService';
import PalletDeviceService from '../../../../services/PalletDeviceService';
import { WarehouseSummary } from '../../../../models/Warehouse';
import PalletDevice from '../../../../models/PalletDevice';
import SweepsControls from './SweepsControls';
import { useNavigate } from 'react-router-dom';
import PermissionService from '../../../../services/PermissionService';
import UserContext from '../../../../context/UserContext';
import { useLanguage } from '../../../../context/LanguageContext';



const Sweeps: React.FC = () => {
  const isMobile = useContext(UserContext).isMobile;
  const { language } = useLanguage();
  const [warehouses, setWarehouses] = useState<WarehouseSummary[]>([]);
  const [devices, setDevices] = useState<PalletDevice[]>([]);
  const [sweeps, setSweeps] = useState<SweepBase[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nowProcessing, setNowProcessing] = useState<number[]>([]);
  const [warehouseFilter, setWarehouseFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [countingNumberFilter, setCountingNumberFilter] = useState<string>('');
  const [writePermission, setWritePermissions] = useState<Boolean>(false);
  const [count, setCount] = useState<number>(10);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const navigate = useNavigate();
  const abortControllerRef = useRef<AbortController | null>(null);

  const onPageChange = useCallback((page: number, pageSize: number) => {
    setPage(page)
    setFilterLoading(true)
    setPageSize(pageSize)
  }, []);

  const updateContent = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    SweepService.getAll(navigate, page, pageSize, warehouseFilter, statusFilter, countingNumberFilter, false, abortController.signal).then((sweeps) => {
      if (sweeps == null) {
        return;
      }
      const processing = [];
      for (let sweep of sweeps.results) {
        if (sweep.warehouse && (sweep.status === 'processing' || sweep.status === 'creating')) {
          processing.push(sweep.warehouse.idx);
        }
      }
      setCount(sweeps.total_count);
      setNowProcessing(processing);
      setSweeps(sweeps.results);
      setLoading(false);
      setFilterLoading(false);
    });
    // eslint-disable-next-line
  }, [navigate, page, pageSize, warehouseFilter, statusFilter, countingNumberFilter]);

  const updateServices = useCallback(() => {
    WarehouseService.getAll(navigate).then((warehouses) => {
      if (warehouses == null) {
        return;
      }
      PalletDeviceService.getAll().then((devices) => {
        if (devices == null) {
          return;
        }
        PermissionService.getSweepWritePermission().then((permission) => {
          setWritePermissions(permission);
          setWarehouses(warehouses);
          setDevices(devices);
          setLoading(false);
        });
      });
    });
  }, [navigate]);

  const onApplyFilters = useCallback(
    (warehouse: string[], countingNumber: string, status: string[]) => {
      setPage(1);
      setWarehouseFilter(warehouse);
      setStatusFilter(status);
      setCountingNumberFilter(countingNumber);
      setFiltersApplied(true);
      setFilterLoading(true)
    },
    []
  );

  useEffect(() => {
    if (filtersApplied) {
      updateContent();
    }
    // eslint-disable-next-line
  }, [page, pageSize, warehouseFilter, statusFilter, countingNumberFilter]);

  useEffect(() => {
    updateServices();
    updateContent();
    const interval = setInterval(() => {
      updateContent();
      updateServices();
    }, 600000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const submitLoading = (state: boolean) => {
    setLoading(state);
  };

  return (
    <Spin spinning={loading}>
      <Card
        title={language === "es" ? "Barridos" : "Sweeps"}
        extra={
          <Button
            type="primary"
            onClick={showModal}
            icon={<PlusOutlined />}
            shape="round"
            disabled={!writePermission}
            style={{ margin: isMobile ? '0 5px' : '0' }}
          >
            {language === "es" ? "Nuevo" : "New"}
          </Button>
        }
        style={{
          margin: isMobile ? '10px' : '20px',
          padding: isMobile ? '10px' : '20px',
        }}
      >
        <SweepsCreate
          isVisible={isModalOpen}
          onClose={closeModal}
          onUpdateContent={updateContent}
          warehouses={warehouses}
          devices={devices}
          nowProcessing={nowProcessing}
          sweeps={sweeps}
          onSubmitLoading={submitLoading}
        />
        <SweepsControls
          loading={filterLoading}
          onApply={onApplyFilters}
          warehouses={warehouses}
        />
        <Divider />
        <SweepsTable
          writePermission={writePermission}
          page={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          total={count}
          sweeps={sweeps}
          onUpdateContent={updateContent}
          isMobile={isMobile}
          loading={filterLoading}
        />
      </Card>
    </Spin>
  );
};

export default Sweeps;
