import React, { useCallback, useEffect, useState } from "react";
import { Col, Progress, Row, Spin, Tag } from "antd";
import {
  WifiOutlined,
  ClockCircleOutlined,
  ApiOutlined,
  DatabaseOutlined,
  BuildOutlined,
  CodeSandboxOutlined,
} from "@ant-design/icons";
import { PalletDeviceDetails } from "../../../../../models/PalletDevice";
import PalletDeviceService from "../../../../../services/PalletDeviceService";

interface HardwareInfoProps {
  deviceIdx: Number;
  parentWidth: number;
  parentHeight: number;
}

const HardwareInfo: React.FC<HardwareInfoProps> = ({ deviceIdx, parentHeight, parentWidth }) => {
  const [hardwareStatus, setHardwareStatus] = useState<PalletDeviceDetails>();
  const [loading, setLoading] = useState(true);

  const connectionStatusTag = (status: string) => {
    let icon, color;
    switch (status) {
      case "connected":
        icon = <WifiOutlined />;
        color = "#87d068";
        break;

      case "disconnected":
        icon = <WifiOutlined />;
        color = "#f50";
        break;

      case "connecting":
      default:
        icon = <ClockCircleOutlined />;
        color = "processing";
        break;
    }
    return <Tag style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.85vw" }} icon={icon} color={color}></Tag>;
  };

  const batteryStatusTag = (status: boolean) => {
    const icon = <ApiOutlined />;
    const color = status ? "#87d068" : "#f50";
    return <Tag style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.85vw" }} icon={icon} color={color}></Tag>;
  };

  const updateContent = useCallback(() => {
    PalletDeviceService.getOne((deviceIdx).toString()).then((response) => {
      if (response == null) {
        return;
      }
      setHardwareStatus(response);
      setLoading(false);
    });
  }, [deviceIdx]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 50000);
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <Spin spinning={loading}>
      {hardwareStatus && hardwareStatus.hardware_status && (
        <Row gutter={[10, 10]} justify="center" align="middle" style={{ overflow: "hidden" }}>
          {/* Disk Status */}
          <Col span={4}>
            <b style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.90vw" }}>Disk:</b>
          </Col>
          <Col span={3}>
            <Tag style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.85vw" }} icon={<DatabaseOutlined />} color={"#114373"}></Tag>
          </Col>
          <Col span={17}>
            <Progress
              percent={parseFloat((hardwareStatus.hardware_status.general.disk_status * 100).toFixed(2))}
              strokeLinecap="butt"
              steps={10}
              size={[parentWidth / 17 - 7, parentHeight / 20]}
            />
          </Col>

          {/* RAM Status */}
          <Col span={4}>
            <b style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.90vw" }}>RAM:</b>
          </Col>
          <Col span={3}>
            <Tag style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.85vw" }} icon={<BuildOutlined />} color={"#114373"}></Tag>
          </Col>
          <Col span={17}>
            <Progress
              percent={parseFloat((hardwareStatus.hardware_status.general.ram_status * 100).toFixed(2))}
              strokeLinecap="butt"
              steps={10}
              size={[parentWidth / 17 - 7, parentHeight / 20]}
            />
          </Col>

          {/* CPU Status */}
          <Col span={4}>
            <b style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.90vw" }}>CPU:</b>
          </Col>
          <Col span={3}>
            <Tag style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.85vw" }} icon={<CodeSandboxOutlined />} color={"#114373"}></Tag>
          </Col>
          <Col span={17}>
            <Progress
              percent={parseFloat((hardwareStatus.hardware_status.general.cpu_status * 100).toFixed(2))}
              strokeLinecap="butt"
              steps={10}
              size={[parentWidth / 17 - 7, parentHeight / 20]}
            />
          </Col>

          {/* Wi-Fi Status */}
          <Col span={4}>
            <b style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.90vw" }}>Wi-Fi:</b>
          </Col>
          <Col span={3}>{connectionStatusTag(hardwareStatus.hardware_status.connection.connection_status)}</Col>
          <Col span={17}>
            <Progress
              percent={parseFloat((hardwareStatus.hardware_status.connection.signal_strength * 100).toFixed(2))}
              strokeLinecap="butt"
              steps={10}
              size={[parentWidth / 17 - 7, parentHeight / 20]}
            />
          </Col>

          {/* Battery Status */}
          <Col span={4}>
            <b style={{ fontSize: window.innerWidth < 768 ? "3vw" : "0.90vw" }}>Battery:</b>
          </Col>
          <Col span={3}>{batteryStatusTag(hardwareStatus.hardware_status.battery.is_charging)}</Col>
          <Col span={17}>
            <Progress
              percent={parseFloat((hardwareStatus.hardware_status.battery.battery_level * 100).toFixed(2))}
              strokeLinecap="butt"
              steps={10}
              size={[parentWidth / 17 - 7, parentHeight / 20]}
            />
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default HardwareInfo;
