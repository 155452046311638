import { useCallback, useEffect, useMemo, useState } from "react";
import { Divider, Spin} from "antd";
import { SweepPositionShow } from "../../../../../../models/Sweep";
import SummaryTable from "./SummaryTable";
import PositionsControls from "./PositionsControls";

interface NotReadRevisionProps {
  positions: SweepPositionShow[];
  sweepId: string;
  onUpdateContent: () => void;
  sweepStatus: string
}
function UnexploredRevision(props: NotReadRevisionProps) {
  const [loading, setLoading] = useState(true);
  const [positions, setPositions] = useState<SweepPositionShow[]>([]);
  const [positionFilter, setPositionFilter] = useState<string>("");
  const [palletFilter, setPalletFilter] = useState<string>("");
  const [corridorFilter, setCorridorFilter] = useState<string>("");
  const [columnFilter, setColumnFilter] = useState<string>("");
  const [levelFilter, setLevelFilter] = useState<string>("");

  const onApplyFilters = useCallback(
    (
      position: string,
      pallet: string,
      corridor: string,
      column: string,
      level: string,
    ) => {
      setPositionFilter(position);
      setPalletFilter(pallet);
      setCorridorFilter(corridor);
      setColumnFilter(column);
      setLevelFilter(level);
    },
    []
  );

  const updateContent = useCallback(() => {
    setLoading(true);

    const stateFilter = "unknown"
    var positions_ = trimSpacesInPositionLabels(props.positions)
    positions_ = positions_.filter((entry) => {
      if (entry.state === stateFilter) {
        return entry.state
          .includes(stateFilter);
      } else {
        return null
      }
    })
    setPositions(positions_)
    setLoading(false);


    // eslint-disable-next-line
  }, [props.positions]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 6000000);
    return () => clearInterval(interval);
  }, [updateContent]);
  const trimSpacesInPositionLabels = (sweepPositions: SweepPositionShow[]): SweepPositionShow[] => {
    return sweepPositions.map(position => {
      position.position_label = position.position_label.trim();
      return position;
    });
  };
  const filteredPositions = useMemo(() => {
    let filtered = positions;
    if (positionFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position_label
          .toLocaleUpperCase()
          .includes(positionFilter.toLocaleUpperCase())
      );
    }
    if (palletFilter !== "" ) {
      filtered = filtered.filter((entry) => {
        if (entry.pallet_label === undefined || entry.pallet_label === null) {
          return "empty"
            .toLocaleUpperCase()
            .includes(palletFilter.toLocaleUpperCase());
        }
        return entry.pallet_label
          .toLocaleUpperCase()
          .includes(palletFilter.toLocaleUpperCase());
      });
    }
    if (corridorFilter !== "" && positionFilter === "") {
      filtered = filtered.filter((entry) =>
        entry.position_label
          .toLocaleUpperCase()
          .slice(0, entry.position_label.length-3)
          .includes(corridorFilter.toLocaleUpperCase())
      
      );
    }
    if (columnFilter !== "" && positionFilter === "") {
      filtered = filtered.filter((entry) =>
          entry.position_label
            .toLocaleUpperCase()
            .slice(entry.position_label.length-3, entry.position_label.length-1)
            .includes(columnFilter.toLocaleUpperCase())
        
      );
    }
    if (levelFilter !== "" && positionFilter === "") {
      filtered = filtered.filter((entry) =>
        entry.position_label[entry.position_label.length-1]
          .includes(levelFilter)
      
      );
    }
    return filtered;
  }, [
    positions,
    positionFilter,
    palletFilter,
    corridorFilter,
    columnFilter,
    levelFilter,
  ]);
  return (
    <Spin spinning={loading}>
      <PositionsControls onApply={onApplyFilters}/>
      <Divider/>
      <SummaryTable positions={filteredPositions}
      sweepStatus = {props.sweepStatus}
      sweepId={props.sweepId ? parseInt(props.sweepId) : 0}
      onUpdateContent={props.onUpdateContent}
      repeatedPositions={[]} />
    </Spin>
  );
}

export default UnexploredRevision;