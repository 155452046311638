import React from "react";
import { Tag, Divider } from "antd";

const RackTemplateColorReferences = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Divider orientation="center">Color References</Divider>
      <Tag color="#4CAF50">Available</Tag>
      <Tag color="#FF5252">Ignored</Tag>
    </div>
  );
};

export default RackTemplateColorReferences;