import React, { useCallback, useContext, useEffect, useState } from "react";

import { CaretLeftOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import SupervisionService from "../../../../../../services/SupervisionService";
import { SupervisionDetails } from "../../../../../../models/Supervision";
import { Button, Card, Divider, Spin } from "antd";
import CorridorColorReferences from "./../details/CorridorColorReferences";
import CorridorDetailsInfo from "./../details/CorridorDetailsInfo";
import CorridorHistoryMap from "./CorridorHistoryMap ";
import UserContext from "../../../../../../context/UserContext";


const CorridorHistoryDetail = () => {
  const { warehouseIdx, sweepIdx, corridorIdx } = useParams();
  const [supervisionDetails, setsupervisionDetails] = useState<SupervisionDetails>();
  const [loading, setLoading] = useState(true);
  const isMobile=useContext(UserContext)
  const navigate=useNavigate()
  const updateContent = useCallback(() => {
    setLoading(true)
    if (warehouseIdx && corridorIdx && sweepIdx) {
      SupervisionService.getSupervisionHistoryDetails(parseInt(warehouseIdx), parseInt(sweepIdx), parseInt(corridorIdx),navigate).then((supervisionDetails) => {
        if (supervisionDetails == null) {
          return;
        }
        setsupervisionDetails(supervisionDetails);
        setLoading(false);
      });
    }
  }, [corridorIdx, sweepIdx,  warehouseIdx,navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 500000);
    return () => clearInterval(interval);
  }, [updateContent]);


  return (
    <Spin spinning={loading}>
      <Card
      title={`Corridor: ${supervisionDetails?.corridor_name}`}
      style={{
        margin: isMobile ? '10px' : '20px',
        padding: isMobile ? '10px' : '20px',
      }} 
      extra={<Link to={`/supervision/history/${warehouseIdx}/${sweepIdx}`}>
      <Button type="primary" icon={<CaretLeftOutlined />} shape="round">
        Back
      </Button>
    </Link>}>
        {supervisionDetails && <CorridorDetailsInfo supervisiondetails={supervisionDetails}
          loading={loading} />}
        <CorridorColorReferences />
        <Divider></Divider>
        {supervisionDetails && sweepIdx && <CorridorHistoryMap corridorIdx={corridorIdx} sweepIdx={sweepIdx} supervisiondetails={supervisionDetails} />}
      </Card>
    </Spin>
  );
};

export default CorridorHistoryDetail;
