import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card, Spin, Tabs, TabsProps, Tag } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import WarehouseShow, { PendingCount } from "../../../../../models/Warehouse";
import WarehouseService from "../../../../../services/WarehouseService";
import CorridorsTable from "./tab1/CorridorsTable";
import Positions from "./tab2/Positions";
import WarehouseMap from "./tab3/WarehouseMap";
import BigPicture from "./tab4/BigPicture";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import SettingService from "../../../../../services/SettingsService";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import UserContext from "../../../../../context/UserContext";

const WarehouseDetails = () => {
  const  isMobile  = useContext(UserContext).isMobile;
  const { warehouseId } = useParams();
  const screens = useBreakpoint();
  const [warehouse, setWarehouse] = useState(new WarehouseShow());
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pendingCount, setPendingCount] = useState<PendingCount>();
  const [showPictures, setShowPictures] = useState<boolean>(false)
  let tabId = searchParams.get("tab") || "1";
  const navigate = useNavigate();

  const updateContent = useCallback(() => {
    if (warehouseId) {
      WarehouseService.getOne(warehouseId, navigate).then((warehouse) => {
        if (warehouse == null) {
          return;
        }
        setWarehouse(warehouse);
        setLoading(false);
      });
      SettingService.getSettings().then((setting) => {
        if (setting == null) {
          return;
        }
        setShowPictures(setting.show_warehouse_positions_pictures)
      })
    }
  }, [warehouseId, navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const updatePending = useCallback(() => {
    if (warehouseId) {
      WarehouseService.getPendingCount(warehouseId).then((count) => {
        if (count == null) {
          return;
        }
        setPendingCount(count);
      });
    }
  }, [warehouseId]);

  useEffect(() => {
    updatePending();
    const interval = setInterval(() => {
      updatePending();
    }, 60000);
    return () => clearInterval(interval);
  }, [updatePending]);

  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Corridors",
      children: <CorridorsTable corridors={warehouse.corridors} />,
    },
    {
      key: "2",
      label: "Positions",
      children: (
        <>
          {warehouseId === undefined ? (
            <></>
          ) : (
            <Positions warehouseId={warehouseId} isMobile={isMobile} showPictures={showPictures} />
          )}
        </>
      ),
    },
    ...(!screens.lg
      ? []
      : [
        {
          key: "3",
          label: "Map",
          children: (
            <>
              {warehouseId === undefined ? (
                <></>
              ) : (
                <WarehouseMap warehouseId={warehouseId} isMobile={isMobile} />
              )}
            </>
          ),
        },
        {
          key: "4",
          label: "Big Picture",
          children: (
            <>
              {warehouseId === undefined ? (
                <></>
              ) : (
                <BigPicture warehouseId={warehouseId} warehouse={warehouse} />
              )}
            </>
          ),
        },]),
      ];
  return (
    <>
      <Spin spinning={loading}>
        <Card
          title={(!screens.lg? true: false) ? warehouse.name + " - " + warehouse.warehouse_number : "Warehouse: " + warehouse.name + " - " + warehouse.warehouse_number}
          extra={
            pendingCount && <> <Tag color={pendingCount.pending_count > 0 ? "blue" : "green"} icon={pendingCount.pending_count > 0 ? <SyncOutlined spin /> : <CheckCircleOutlined />}>
              {(!screens.lg? true: false) ? pendingCount.pending_count > 0 ? "Pending to JD: " + pendingCount.pending_count : "JD up to date" : pendingCount.pending_count > 0 ? "Pending readings to JD: " + pendingCount.pending_count : "Readings to JD up to date"}
            </Tag>
              {(screens.lg? true: false) && <div style={{ fontSize: window.innerWidth < 900 ? "3vw" : "80%", color: "#7a848d" }}>
                {"last update: " + pendingCount.last_update}
              </div>}</>
          }
        >
          <Tabs
            items={tabs}
            activeKey={tabId}
            onTabClick={(activeKey) => {
              setSearchParams({ tab: activeKey });
            }}
          />
        </Card>
      </Spin>
    </>
  );
};

export default WarehouseDetails;
