import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import UserContext from "../context/UserContext";
import { UserShow } from "../models/User";

// Utility function to detect if the device is mobile
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const PrivateRoutes = () => {
  const [currentUser, setCurrentUser] = useState<UserShow | undefined>(); // initially undefined
  const [isMobile, setIsMobile] = useState<boolean>(false); // mobile device state
  const navigate = useNavigate();
  const prevLocation = useLocation();

  //GUARD
  useEffect(() => {
    // Detect mobile device on first render
    setIsMobile(isMobileDevice());

    UserService.validateAccessToken()
      .then((user: UserShow) => {
        setCurrentUser(user);
      })
      .catch((err) => {
        setCurrentUser(undefined);
        if (err.status === 401) {
          navigate(`/login?redirectTo=${prevLocation.pathname}`);
        } else {
          navigate("/500");
        }
      });
  }, [navigate, prevLocation]);

  if (currentUser === undefined) {
    return null; // You can replace this with a loading spinner if needed
  }

  return currentUser ? (
    <UserContext.Provider value={{ user: currentUser, isMobile:isMobile}}>
      <Outlet />
    </UserContext.Provider>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
