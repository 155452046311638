import axios from "axios";
import UserService from "./UserService";

const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "pictures/";

export default class SweepPictureService {
  public static async getSweepPositionPicture(sweep_idx: number,postion_label: string): Promise<Blob | null> {
    try {
      return await axios
        .get(API_BASE_URL + API_RESOURCE + "sweeps/positions/picture/" + sweep_idx + "/" + postion_label,
          {
          headers: {
            ...UserService.getAuthHeader(),
            "Cache-Control": "no-cache",
          },
          responseType: "blob",
        })
        .then((res) => res.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public static async getSweepPalletPicture(sweep_idx: number,postion_label: string): Promise<Blob | null> {
    try {
      return await axios
        .get(API_BASE_URL + API_RESOURCE + "sweeps/pallets/picture/" + sweep_idx + "/" + postion_label,
          {
          headers: {
            ...UserService.getAuthHeader(),
            "Cache-Control": "no-cache",
          },
          responseType: "blob",
        })
        .then((res) => res.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}


export class WarehousePictureService {
  public static async getWarehousePositionPicture(warehouse_idx: number,postion_label: string): Promise<Blob | null> {
    try {
      return await axios
        .get(API_BASE_URL + API_RESOURCE + "warehouses/positions/picture/" + warehouse_idx + "/" + postion_label,
          {
          headers: {
            ...UserService.getAuthHeader(),
            "Cache-Control": "no-cache",
          },
          responseType: "blob",
        })
        .then((res) => res.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getWarehousePalletPicture(warehouse_idx: number,postion_label: string): Promise<Blob | null> {
    try {
      return await axios
        .get(API_BASE_URL + API_RESOURCE + "warehouses/pallets/picture/" + warehouse_idx + "/" + postion_label,
          {
          headers: {
            ...UserService.getAuthHeader(),
            "Cache-Control": "no-cache",
          },
          responseType: "blob",
        })
        .then((res) => res.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}